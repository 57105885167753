(function(angular) {

angular.module('app.common.iq', ['app.common.translation', 'app.common.component', 'app.common.quiz', 'app.common.environment', 'app.common.facebook'])

    .config(['quizBuilderProvider', function (provider) {
        provider.addBuilder('iq_test', ['iqTestBuilder', function(builder) {
            return builder;
        }])
    }])

    .config(['quizStateSerializerProvider', function (provider) {
        provider.addSerializer('iq_test', ['iqStateSerializer', function(serializer) {
            return serializer;
        }])
    }])

    .config(['quizResultDeserializerProvider', function (provider) {
        provider.addDeserializer('iq_test', ['iqResultDeserializer', function(deserializer) {
            return deserializer;
        }])
    }])

    .factory('iqStateSerializer', ['quizPaywall', function(quizPaywall) {
        return {
            serialize: function(quiz) {
                return {
                    user: quizPaywall.getState(),
                    answers: quiz.questions.getState()
                }
            },
            deserialize: function(quiz, state) {
                quizPaywall.setState(state.user || {});
                quiz.questions.setState(state.answers || []);

                return true;
            }
        }
    }])

    .factory('iqResultDeserializer', ['iqResults', function(results) {
        return function(quiz, resultData) {
            results.setState(resultData || {});

            return true;
        }
    }])

    .factory('iqResults', ['IqResults', function(IqResults) {
        return new IqResults;
    }])

    .factory('iqTestBuilder', ['$log', 'QuizPage', 'QuizQuestion', 'quizPaywall', 'iqResults', 'iqTestBuilderHelpers', 'sha256', function($log, QuizPage, QuizQuestion, quizPaywall, iqResults, helpers, sha256) {

        function prepareQuizData(data) {
            return angular.merge({
                siteNameTextId: 'unknown',
                siteUrlTextId:  'unknown',
                description:    '',
                information:    [],
                sections:       []
            }, data);
        }

        function prepareSectionData(data) {
            return angular.extend({
                id:                      'unknown',
                titleTextId:             'unknown',
                instructionsTextId:      'unknown',
                instructionsBreakTextId: 'unknown',
                questions:               []
            }, data);
        }

        function prepareSectionQuestions(section) {
            var questions = {
                test: null,
                real: []
            };

            angular.forEach(section.questions, function(data) {
                var question = new QuizQuestion(data);

                if (!question) {
                    $log.error('could not create question, or the name was not unique')
                    return;
                }

                if (data.test) {
                    questions.test = question;
                    return;
                }

                questions.real.push(question);
            });

            return questions;
        }

        return function(quiz, data) {

            // Make sure that the quiz contains the fields we need
            var data = prepareQuizData(data);
            var paywallAdded = false;

            // Add an intro page
            quiz.pages.add(new QuizPage({
                id: 'intro',
                components: [helpers.makeWrapper('intro', [
                    helpers.makeHeader('general.intro_header'),
                    helpers.makeColumns([
                        helpers.makeColumn(8, [
                            helpers.makeInstructionTabs(data.instructions),
                        ])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(4, [
                            helpers.makeAdvanceButton()
                        ])
                    ])
                ])]
            }));

            // Prepare section progression
            var makeSections = helpers.makePrepareQuestionSections(data.sections);

            // Create pages for each section:
            // * Instruction page with optional test question
            // * A page for each question
            // * A results page showing the responses for questions in the section
            angular.forEach(data.sections, function(section, sectionIndex) {
                var section = prepareSectionData(section);
                var questions = prepareSectionQuestions(section);
                var lastSection = sectionIndex == (data.sections.length - 1);

                if (questions.test && quiz.questions.add(questions.test)) {
                    quiz.pages.add(new QuizPage({
                        id: section.id,
                        isProgress: true,
                        components: [helpers.makeWrapper('section-' + section.id, [
                            makeSections(section.id),
                            helpers.makeProgressBar(),
                            helpers.makeHeader(section.titleTextId),
                            helpers.makeMarkdown(section.instructionsTextId),
                            helpers.makeQuestion(questions.test),
                            helpers.makeAdvanceIfCorrectButton(questions.test)
                        ])],
                        isCompleted: function() {
                            return questions.test.isAnswered() && questions.test.isCorrect();
                        }
                    }));
                } else {
                    quiz.pages.add(new QuizPage({
                        id: section.id,
                        isProgress: true,
                        components: [helpers.makeWrapper('section-' + section.id, [
                            makeSections(section.id),
                            helpers.makeProgressBar(),
                            helpers.makeHeader(section.titleTextId),
                            helpers.makeMarkdown(section.instructionsTextId),
                            helpers.makeAdvanceButton()
                        ])]
                    }));
                }

                var addedMemoryBackwardsBreak = false;

                // Create a page for each question
                angular.forEach(questions.real, function(question, questionIndex) {

                    var lastQuestion = questionIndex == (questions.real.length - 1);

                    // We need to insert a break page when the reversed memory sequences start
                    if (!addedMemoryBackwardsBreak && question.type == 'memorySequence' && question.reversed) {
                        addedMemoryBackwardsBreak = true;

                        quiz.pages.add(new QuizPage({
                            id: section.id + '-break',
                            isProgress: true,
                            components: [helpers.makeWrapper('section-' + section.id, [
                                makeSections(section.id),
                                helpers.makeProgressBar(),
                                helpers.makeHeader(section.titleTextId),
                                helpers.makeMarkdown(section.instructionsBreakTextId),
                                helpers.makeAdvanceButton()
                            ])]
                        }));
                    }

                    quiz.pages.add(new QuizPage({
                        id: question.id,
                        isProgress: true,
                        isSync: lastQuestion, // sync on last question
                        components: [helpers.makeWrapper('section-' + section.id, [
                            makeSections(section.id),
                            helpers.makeProgressBar(),
                            helpers.makeHeader('general.question_header', {
                                index: questionIndex + 1,
                                total: questions.real.length
                            }),
                            helpers.makeQuestion(quiz.questions.add(question)),
                            helpers.makeAdvanceIfAnsweredButton(question)
                        ])],
                        isCompleted: function() {
                            return question.isAnswered();
                        }
                    }));
                });

                // Insert paywall before language section.
                if (section.id == 'figure') {
                    if (!paywallAdded) {
                        paywallAdded = true;

                        quiz.pages.add(new QuizPage({
                            id: 'wait',
                            isSync: true,
                            components: [
                                helpers.makeHeader('general.paywall_header'),
                                /* helpers.makeMarkdown('general.paywall_text_section'), */
                                helpers.makeMarkdown('general.paywall_text'),
                                helpers.makePaywall(quiz)
                            ],
                            isCompleted: function() {
                                var userId = sha256.convertToSHA256(quizPaywall.getEmail());
                                dataLayer.push({
                                    "event": "newsletterSubscribeSubmit",
                                    "newsletterSubscriptionFormat": "between questions",
                                    "userId": userId
                                });
                                if (quizPaywall.isValid && window.newsletterSubscribe) {
                                    dataLayer.push({
                                        "event": "newsletterSubscribeSuccess",
                                        "newsletterSubscriptionFormat": "between questions",
                                        "userId": userId
                                    });
                                    window.newsletterSubscribe = true;
                                }

                                return quizPaywall.isValid();
                            }
                        }));
                    }
                }

                // Do not show on last page
                // Do not show just before paywall
                if (section.id != 'calculation' && section.id != 'figure') {
                    quiz.pages.add(new QuizPage({
                        id: section.id + '-result',
                        components: [helpers.makeWrapper('section-' + section.id, [
                            makeSections(section.id),
                            helpers.makeProgressBar(),
                            helpers.makeHeader('general.section_result_header', {title: section.title}),
                            helpers.makeMarkdown('general.section_result_text'),
                            helpers.makeColumns([
                                helpers.makeColumn(1, []),
                                helpers.makeColumn(5, [helpers.makeProgressSaveButton()]),
                                helpers.makeColumn(5, [
                                    {
                                        type: 'iqShareFacebookSectionResult',
                                        section: section
                                    }
                                ]),
                                helpers.makeColumn(1, [])
                            ]),
                            helpers.makeColumns([
                                helpers.makeColumn(4, []),
                                helpers.makeColumn(4, [helpers.makeAdvanceButton()]),
                                helpers.makeColumn(4, [])
                            ]),

                        ])]
                    }));
                }
            });

            quiz.pages.add(new QuizPage({
                id: 'result',
                components: [helpers.makeWrapper('result', [
                    {
                        type: 'iqResultTotal',
                        header: 'general.result_score_header',
                        score: function() {
                            return iqResults.getOverallScore();
                        }
                    },
                    {
                        type: 'iqResultDistribution',
                        header: 'general.result_score_overall_header',
                        score: function() {
                            return iqResults.getOverallScore();
                        }
                    },
                    {
                        type: 'iqResultDistribution',
                        header: 'general.result_score_verbal_header',
                        score: function() {
                            return iqResults.getVerbalScore();
                        }
                    },
                    {
                        type: 'iqResultDistribution',
                        header: 'general.result_score_performance_header',
                        score: function() {
                            return iqResults.getPerformanceScore();
                        }
                    },
                    helpers.makeColumns([
                        helpers.makeColumn(3, []),
                        helpers.makeColumn(6, [
                            helpers.makeWrapper('block', [
                                helpers.makeMarkdown('general.result_text_above_buttons')
                            ])
                        ]),
                        helpers.makeColumn(3, [])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(4, [{ type: 'iqResultButtonRestart'         }]),
                        helpers.makeColumn(4, [{ type: 'iqResultButtonPrint'         }]),
                        helpers.makeColumn(4, [{ type: 'iqResultButtonShareFacebook' }])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(3, []),
                        helpers.makeColumn(6, [helpers.makeMarkdown('general.result_text_below_buttons')]),
                        helpers.makeColumn(3, [])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(6, [
                            helpers.makeWrapper(['section-figure', 'small'], [{
                                type:   'iqResultSection',
                                header: 'general.result_score_section_figure_header',
                                text:   'general.result_score_section_figure_text',
                                score:  function() { return iqResults.getSectionScore('figure'); }
                            }])
                        ]),
                        helpers.makeColumn(6, [
                            helpers.makeWrapper(['section-language', 'small'], [{
                                type:   'iqResultSection',
                                header: 'general.result_score_section_language_header',
                                text:   'general.reuslt_score_section_language_text',
                                score:  function() { return iqResults.getSectionScore('language'); }
                            }])
                        ])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(6, [
                            helpers.makeWrapper(['section-memory', 'small'], [{
                                type:   'iqResultSection',
                                header: 'general.result_score_section_memory_header',
                                text:   'general.reuslt_score_section_memory_text',
                                score:  function() { return iqResults.getSectionScore('memory'); }
                            }])
                        ]),
                        helpers.makeColumn(6, [
                            helpers.makeWrapper(['section-logic', 'small'], [{
                                type:   'iqResultSection',
                                header: 'general.result_score_section_logic_header',
                                text:   'general.reuslt_score_section_logic_text',
                                score:  function() { return iqResults.getSectionScore('logic'); }
                            }])
                        ])
                    ]),
                    helpers.makeColumns([
                        helpers.makeColumn(6, [
                            helpers.makeWrapper(['section-calculation', 'small'], [{
                                type:   'iqResultSection',
                                header: 'general.result_score_section_calculation_header',
                                text:   'general.result_score_section_calculation_text',
                                score:  function() { return iqResults.getSectionScore('calculation'); }
                            }])
                        ]),
                        helpers.makeColumn(6, [
                            helpers.makeWrapper('block', [
                                helpers.makeMarkdown('general.result_more_info_about_iq_text')
                            ])
                        ])
                    ])
                ])]
            }));

            return true;
        }

    }])


    .factory('iqTestBuilderHelpers', [function() {

        var helpers = {};

        helpers.makeHeader = function(id, data) {
            return {
                type: 'header',
                id:   id,
                data: data || {}
            };
        };

        helpers.makeParagraph = function(id, data) {
            return {
                type: 'paragraph',
                id:   id,
                data: data || {}
            }
        };

        helpers.makeMarkdown = function(id, data) {
            return {
                type: 'markdown',
                id:   id,
                data: data || {}
            }
        };

        helpers.makeList = function(items) {
            return {
                type: 'list',
                items: items,
            };
        };

        helpers.makeAdvanceButton = function() {
            return {
                type: 'advanceButton',
            };
        };

        helpers.makeAdvanceIfAnsweredButton = function(question) {
            return {
                type: 'advanceIfAnsweredButton',
                question: question
            };
        };

        helpers.makeAdvanceIfCorrectButton = function(question) {
            return {
                type: 'advanceIfCorrectButton',
                question: question
            };
        };

        helpers.makeWrapper = function(areas, components) {

            if (angular.isString(areas)) {
                areas = [areas];
            }

            return {
                type: 'iqWrapper',
                areas: areas,
                components: components
            };
        };

        helpers.makeTabs = function(tabs) {
            return {
                type: 'tabs',
                tabs: tabs
            };
        };

        helpers.makeTab = function(headerTextId, components) {
            return {
                headerTextId: headerTextId,
                components: components
            };
        };

        helpers.makeColumns = function(columns) {
            return {
                type: 'columns',
                columns: columns
            };
        };

        helpers.makeColumn = function(width, components) {
            return {
                width: width,
                components: components,
            }
        };

        helpers.makeProgressBar = function() {
            return {
                type: 'progressBar'
            };
        };

        helpers.makeInstructionTabs = function(instructions) {
            var tabs = [];

            angular.forEach(instructions, function(instruction) {
                tabs.push(helpers.makeTab(instruction.headerTextId, [
                    helpers.makeMarkdown(instruction.bodyTextId)
                ]));
            });

            return helpers.makeTabs(tabs);
        };

        helpers.makePrepareSections = function(sections) {
            return function(active) {
                return {
                    type:     'sections',
                    sections: sections,
                    active:   active
                }
            }
        };

        helpers.makePrepareQuestionSections = function(sections) {
            var result = [];

            angular.forEach(sections, function(section) {
                result.push({
                    id: section.id,
                    textId: section.titleTextId
                });
            });

            return helpers.makePrepareSections(result);
        };

        helpers.makeQuestion = function(question) {
            switch(question.type) {
            case 'visualMatrix':
                return { type: 'iqQuestionVisualMatrix', question: question };
            case 'memorySequence':
                return { type: 'iqQuestionMemorySequence', question: question };
            case 'textChoice':
                return { type: 'questionTextChoice', question: question };
            case 'timedTextChoice':
                return { type: 'iqQuestionTimedTextChoice', question: question };
            }

            return { type: 'unknown', question: question };
        };

        helpers.makeResultAnswerDistributionBar = function(section) {
            return {
                type: 'iqResultAnswerDistributionBar',
                section: section
            };
        };

        helpers.makePaywall = function(quiz) {
            return {
                type: 'paywall',
                quiz: quiz
            };
        }

        helpers.makeProgressSaveButton = function() {
            return {
                type: 'progressSaveButton'
            };
        };

        return helpers;
    }])

;

})(angular);
