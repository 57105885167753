(function(angular) {

angular.module('app.common.personality')
    .config(['componentProvider', function(provider) {
        provider.register('personalityResultScales', ['personalityResults', '$timeout', function(results, timer) {
            return {
                templateUrl: 'common/personality/component/personalityResultScales/personalityResultScales.html',
                link: function link(scope, element, attrs) {
                    scope.trait  = results.getTrait();
                    scope.scales = results.getScales();

                    // Attach tooltip when everything else have run.
                    timer(
                        function() {
                            $('[data-toggle="tooltip"]').tooltip()
                        },
                        0
                    );

                },
        };
        }]);
    }])

    .directive('personalityResultRadialProgress', [function() {
        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                if ($('.personality-wrapper').hasClass('job')) {
                    use_color = '#00729e';
                }
                else {
                    use_color = '#7D734F';
                }
                reverse = (attrs['personalityResultRadialReverse'] == 'true') ? true : false;

                $(element).circleProgress({
                    value:      attrs['personalityResultRadialProgress'],
                    animation:  false,
                    startAngle: Math.PI * -0.5,
                    size:       125,
                    thickness:  15,
                    fill:       { color: use_color },
                    emptyFill: '#ffffff',
                    reverse: reverse
                });
            }
        };
    }])

;

})(angular);
