(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityProgress', [function() {
            return {
                templateUrl: 'common/personality/component/personalityProgress/personalityProgress.html',
                link: function link(scope, element, attrs) {
                    var progressIndex      = scope.component.progressIndex || 0;
                    var progressTotal      = scope.component.progressTotal || 0;
                    var progressNormalized = progressTotal ? progressIndex / progressTotal : 0;
                    var progressPercentage = Math.max(0, Math.min(100, Math.round(progressNormalized * 100)));

                    scope.percentage = progressPercentage;
                    scope.textId     = scope.component.textId;
                    scope.textData   = scope.component.textData;
                }
            };
        }]);
    }])

;

})(angular);
