(function(angular) {

angular.module('app.common.translation', [])

    .provider('translation', [function() {

        var declarations = {};
        var messages = {};

        function declareMessageKey(key) {
            declarations[key] = key;
        }

        function declareMessageKeys(keys) {
            angular.forEach(keys, function(key) {
                declareMessageKey(key);
            });
        }

        function addMessage(key, message) {
            messages[key] = message;
        }

        function addMessages(mesasges) {
            angular.forEach(mesasges, function(message, key) {
                addMessage(key, message);
            });
        }

        this.addMessage         = addMessage;
        this.addMessages        = addMessages;
        this.declareMessageKey  = declareMessageKey;
        this.declareMessageKeys = declareMessageKeys;

        this.$get = [function() {
            return {
                addMessage:  addMessage,
                addMessages: addMessages,
                getDeclarations: function() {
                    return declarations;
                },
                hasDeclaration: function(key) {
                    return declarations.hasOwnProperty(key);
                },
                getMessages: function() {
                    return messages;
                },
                hasMessage: function(key) {
                    return messages.hasOwnProperty(key);
                },
                getMessage: function(key) {
                    return messages[key] || null;
                }
            };
        }]
    }])

    .factory('translator', ['translation', '$interpolate', function(translation, $interpolate) {
        return function(translationId, interpolateParams) {
            var message = translation.getMessage(translationId);

            if (!message) {
                return translationId;
            }

            if (!interpolateParams) {
                return message;
            }

            return $interpolate(message)(interpolateParams);
        }
    }])

    .filter('translate', ['translator', function(translator) {
        return function (translationId, interpolateParams) {
            return translator(translationId, interpolateParams);
        };
    }])
    .filter("htmlUnsafe", ['$sce', function($sce) {
            return $sce.trustAsHtml;
    }]);

;


})(angular);
