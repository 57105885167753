(function(angular) {

angular.module('app.common.quiz')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.advance_correct_button_waiting',
            'general.advance_correct_button_incorrect',
            'general.advance_correct_button_correct'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('advanceIfCorrectButton', ['quizNavigator', function(quizNavigator) {
            return {
                templateUrl: 'common/quiz/component/advanceIfCorrectButton/advanceIfCorrectButton.html',
                link: function link(scope, element, attrs) {
                    scope.state = function() {
                        if (!scope.component.question.isAnswered()) {
                            return 'waiting';
                        }

                        if (!scope.component.question.isCorrect()) {
                            return 'incorrect';
                        }

                        return 'correct';
                    }

                    scope.advance = function() {
                        if (!scope.component.question.isAnswered() || !scope.component.question.isCorrect()) {
                            return;
                        }

                        quizNavigator.next();
                    }
                }
            };
        }]);
    }])

;

})(angular);
