(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityIntro', ['quizNavigator', function(quizNavigator) {
            return {
                templateUrl: 'common/personality/component/personalityIntro/personalityIntro.html',
                link: function link(scope, element, attrs) {

                }
            };
        }]);
    }])

;

})(angular);
