(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultCharacter', ['personalityResults', function(results) {
            return {
                templateUrl: 'common/personality/component/personalityResultCharacter/personalityResultCharacter.html',
                link: function link(scope, element, attrs) {
                    scope.match  = results.getBestMatch();
                }
            };
        }]);
    }])

;

})(angular);
