(function(angular) {

angular.module('app.common.iq')

    .config(['componentProvider', function(provider) {
        provider.register('iqResultSection', [function() {
            return {
                templateUrl: 'common/iq/component/iqResult/iqResultSection/iqResultSection.html',
                link: function link(scope, element, attrs) {
                    scope.header = scope.component.header;
                    scope.text = scope.component.text;
                    scope.scale = scope.component.score() ? scope.component.score().scale : 0;
                }
            };
        }]);
    }])

;

})(angular);
