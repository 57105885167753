(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('columns', [function() {
            return {
                templateUrl: 'common/component/columns/columns.html',
                link: function link(scope, element, attrs) {
                    scope.columns = scope.component.columns;

                    scope.class = function(width) {
                        return 'col-md-' + width + ' col-sm-' + width;
                    }
                }
            };
        }]);
    }])
;

})(angular);
