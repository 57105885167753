(function(angular) {

angular.module('app.common.nordic')

    .config(['componentProvider', function(provider) {
        provider.register('nordicWrapper', [function() {
            return {
                templateUrl: 'common/nordic/component/nordicWrapper/nordicWrapper.html',
                link: function link(scope, element, attrs) {
                    scope.components = scope.component.components || [];

                    scope.classes = [];

                    angular.forEach(scope.component.areas || [], function(area) {
                        scope.classes.push('nordic-wrapper-' + area);
                    });
                }
            };
        }]);
    }])

;

})(angular);
