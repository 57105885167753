(function(angular) {

angular.module('app.common.facebook', [])

    .factory('facebook', ['$q', function($q) {
        return {
            user: function() {

                dataLayer.push({
                    "event": "socialClickToChannel",
                    "socialPlatform": "Facebook", // the platform the user shares the content to
                });

                var deferred = $q.defer();

                FB.login(function(response) {
                    if (!response.authResponse) {
                        deferred.reject('login cancelled or not fully authorized');
                        return;
                    }

                    FB.api('/me',{ fields: 'first_name,last_name,email'}, function(response) {
                        if (!response) {
                            deferred.reject('no response returned');
                            return;
                        }

                        if (response.error) {
                            deferred.reject('response error: ' + response.error);
                            return;
                        }

                        deferred.resolve({
                            id:        response.id         || 0,
                            email:     response.email      || '',
                            firstName: response.first_name || '',
                            lastName:  response.last_name  || ''
                        });
                    });
                }, {
                    scope: 'public_profile,email',
                    return_scopes: true
                });

                return deferred.promise;
            },
            share: function(data) {
                var deferred = $q.defer();

                if (!data.link) {
                    deferred.reject('invalid link data');
                    return deferred.promise;
                }

                var configuration = {
                    method: 'share_open_graph',
                    action_type: 'og.shares',
                    action_properties: JSON.stringify({
                        object: {
                            'og:url': data.link,
                            'og:title': data.name || null,
                            'og:description': data.description || null,
                            'og:image': data.picture || null
                        }
                    })
                };

                FB.ui(configuration, function (response) {
                    if (!response) {
                        deferred.reject('no response returned');
                        return;
                    }

                    if (response.error) {
                        deferred.reject('response error: ' + response.error);
                        return;
                    }

                    deferred.resolve(response);
                });

                dataLayer.push({
                    "event": "socialShare",
                    "socialPlatform": "Facebook"
                });

                return deferred.promise;
            }
        }
    }])

;


})(angular);
