(function(angular) {

angular.module('app.common.iq')

    .config(['componentProvider', function(provider) {
        provider.register('iqResultButtonPrint', [function() {
            return {
                templateUrl: 'common/iq/component/iqResult/iqResultButtonPrint/iqResultButtonPrint.html',
                link: function link(scope, element, attrs) {
                    
                }
            };
        }]);
    }])

;

})(angular);
