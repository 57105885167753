(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('iqWrapper', [function() {
            return {
                templateUrl: 'common/iq/component/iqWrapper/iqWrapper.html',
                link: function link(scope, element, attrs) {
                    scope.components = scope.component.components || [];

                    scope.classes = [];

                    angular.forEach(scope.component.areas || [], function(area) {
                        scope.classes.push('iq-wrapper-' + area);
                    });
                }
            };
        }]);
    }])

;

})(angular);
