(function (angular) {

    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicAdvanceButton', ['translation', 'quizResultDeserializer', 'quizBuilder', 'redirect', '$state', 'nordicTestBuilder', 'sentinel', 'quiz', '$http', '$rootScope', 'quizApi', function (translation, quizResultDeserializer, quizBuilder, redirect, $state, nordicTestBuilder, sentinel, quiz, $http, $rootScope, quizApi) {

                var obj = this;
                var subject = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

                function httpGet(theUrl) {
                    var xmlHttp = new XMLHttpRequest();
                    xmlHttp.open("GET", theUrl, false); // false for synchronous request
                    xmlHttp.send(null);
                    return JSON.parse(xmlHttp.responseText);
                }

                quizApi.translation.get().then(
                    function success(translationData) {
                        translation.addMessages(translationData.translations);

                        return quizApi.quiz.get();
                    },
                    function error(reason) {
                        return $q.reject('could not fetch translation data');
                    }
                )

                obj.quizzData = httpGet('/api/quiz?subject=' + subject);
                obj.redirectQuizz = obj.quizzData.sections[0].questions[0].id;


                this.advance = function () {
                    quiz.clear();
                    quiz.pages.clearAll();
                    nordicTestBuilder(quiz, obj.quizzData);
                };

                return {
                    templateUrl: 'common/nordic/component/nordicAdvanceButton/nordicAdvanceButton.html',
                    link: function link(scope) {
                        scope.link = scope.component.link + '#/' + obj.redirectQuizz;
                        scope.buttonText = scope.component.buttonText;
                        scope.advance = function (subject) {
                            obj.advance(subject);
                        };
                        scope.subject = scope.component.subject;
                    }
                };

            }]);
        }])
    ;
})(angular);


// (function(angular) {
//
//     angular.module('app.common.nordic')
//         .config(['componentProvider', function(provider) {
//             provider.register('nordicAdvanceButton', ['nordicTestBuilder', 'sentinel','quiz', '$http', '$rootScope', 'quizApi', 'quizStateSerializer',  function (nordicTestBuilder, sentinel, quiz, $http, $rootScope, quizApi, quizStateSerializer) {
//
//                 var obj = this;
//                 var subject  = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
//
//                 function httpGet(theUrl)
//                 {
//                     var xmlHttp = new XMLHttpRequest();
//                     xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
//                     xmlHttp.send( null );
//                     return JSON.parse(xmlHttp.responseText);
//                 }
//
//
//
//                 obj.quizzData = httpGet('/api/quiz?subject=' + subject);
//                 httpGet('/api/translation');
//                 obj.redirectQuizz = obj.quizzData.sections[0].questions[0].id;
//
//
//                 this.advance = function () {
//                     sentinel.setQuizLoaded(false);
//                     quiz.pages.clearAll();
//                         nordicTestBuilder(quiz, obj.quizzData);
//                 };
//
//                 return {
//                     templateUrl: 'common/nordic/component/nordicAdvanceButton/nordicAdvanceButton.html',
//                     link: function link(scope) {
//                         scope.link = scope.component.link + '#/' + obj.redirectQuizz;
//                         scope.buttonText = scope.component.buttonText;
//                         scope.advance = function (subject) {
//                             obj.advance(subject);
//                         };
//                         scope.subject = scope.component.subject;
//                     }
//                 };
//
//             }]);
//         }])
//     ;
// })(angular);
//
//
// // (function(angular) {
// //
// //     angular.module('app.common.nordic')
// //
// //         .config(['componentProvider', function(provider) {
// //             provider.register('nordicAdvanceButton', ['quizNavigator', function (quizNavigator) {
// //                 var obj = this;
// //
// //                 this.advance = function () {
// //                     quizNavigator.next();
// //                 };
// //
// //                 return {
// //                     templateUrl: 'common/nordic/component/nordicAdvanceButton/nordicAdvanceButton.html',
// //                     link: function link(scope) {
// //                         scope.buttonText = scope.component.buttonText;
// //                         scope.link = scope.component.link;
// //                         scope.advance = function () {
// //                             obj.advance();
// //                         };
// //                     }
// //                 };
// //             }]);
// //         }])
// //
// //     ;
// //
// // })(angular);
