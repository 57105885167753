(function(angular) {

angular.module('app.common.iq')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.share_facebook_button',
            'general.share_facebook_link',
            'general.share_facebook_image',
            'general.share_facebook_result_section_text'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('iqShareFacebookSectionResult', ['iqResults', 'facebook', 'translator', function(iqResults, facebook, translator) {
            return {
                templateUrl: 'common/iq/component/iqShareFacebookSectionResult/iqShareFacebookSectionResult.html',
                link: function link(scope, element, attrs) {
                    var questions = iqResults.getSectionQuestions(scope.component.section.id);

                    var params = {
                        section: translator(scope.component.section.namedTextId),
                        total:   questions.total,
                        correct: questions.correct,
                        percent: questions.percentCorrect
                    };

                    scope.params = params;

                    scope.share = function() {
                        var data = {
                            link:    translator('general.share_facebook_link'),
                            picture: translator('general.share_facebook_image'),
                            name:    translator('general.share_facebook_result_section_text', params)
                        };

                        facebook.share(data).then(
                            function success(result) {
                                dataLayer.push({
                                    "event": "socialShare",
                                    "socialPlatform": "Facebook"
                                });
                                //console.log(result);
                            },
                            function error(reason) {
                                //console.error(reason);
                            }
                        )
                    }
                }
            };
        }]);
    }])

;

})(angular);
