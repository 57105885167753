(function(angular) {

angular.module('app.common.nordic')

    .config(['componentProvider', function(provider) {
        provider.register('nordicResultDistribution', [function() {
            return {
                templateUrl: 'common/nordic/component/nordicResult/nordicResultDistribution/nordicResultDistribution.html',
                link: function link(scope, element, attrs) {
                    scope.header = scope.component.header;

                    var score = scope.component.score();
                    scope.percentile = score ? score.percentile : 0;
                    scope.result = score ? score.result : 0;
                }
            };
        }]);
    }])
;
})(angular);
