(function(angular) {

angular.module('app.common.nordic')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.share_facebook_button',
            'general.share_facebook_link',
            'general.share_facebook_image',
            'general.share_facebook_result_section_text'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('nordicShareFacebookSectionResult', ['nordicResults', 'facebook', 'translator', function(nordicResults, facebook, translator) {
            return {
                templateUrl: 'common/nordic/component/nordicShareFacebookSectionResult/nordicShareFacebookSectionResult.html',
                link: function link(scope, element, attrs) {
                    var questions = nordicResults.getSectionQuestions(scope.component.section.id);

                    var params = {
                        section: translator(scope.component.section.namedTextId),
                        total:   questions.total,
                        correct: questions.correct,
                        percent: questions.percentCorrect
                    };

                    scope.params = params;

                    scope.share = function() {
                        var data = {
                            link:    translator('general.share_facebook_link'),
                            picture: translator('general.share_facebook_image'),
                            name:    translator('general.share_facebook_result_section_text', params)
                        };

                        facebook.share(data).then(
                            function success(result) {
                                //console.log(result);
                            },
                            function error(reason) {
                                //console.error(reason);
                            }
                        )
                    }
                }
            };
        }]);
    }])

;

})(angular);
