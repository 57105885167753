(function(angular) {

angular.module('app.common.quiz')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.advance_if_answered_button'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('advanceIfAnsweredButton', ['quizNavigator', '$rootScope', 'quizProgress', function(quizNavigator, $rootScope, quizProgress) {
            return {
                templateUrl: 'common/quiz/component/advanceIfAnsweredButton/advanceIfAnsweredButton.html',
                link: function link(scope, element, attrs) {
                    var questionName  = scope.component.question.id || null;

                    scope.canAdvance = function() {
                        return scope.component.question.isAnswered();
                    };

                    scope.advance = function() {
                        quizNavigator.next();
                    }
                }
            };
        }]);
    }])

;

})(angular);
