(function(angular) {

angular.module('app.common.nordic')

    .factory('NordicResults', ['NordicResultScore', 'NordicResultSection', 'NordicResultQuestions', function(NordicResultScore, NordicResultSection, NordicResultQuestions) {
        return function() {

            var overall     = new NordicResultScore({});
            var performance = new NordicResultScore({});
            var verbal      = new NordicResultScore({});
            var questions   = new NordicResultQuestions({});
            var sections    = [];

            this.setState = function(state) {
                state = angular.merge({
                    sections: {},
                    questions: {},
                    score: {
                        overall:     {},
                        performance: {},
                        verbal:      {}
                    }
                }, state);

                overall     = new NordicResultScore(state.score.overall);
                performance = new NordicResultScore(state.score.performance);
                verbal      = new NordicResultScore(state.score.verbal);
                questions   = new NordicResultQuestions(state.questions);
                sections    = [];

                angular.forEach(state.sections, function(result) {
                    sections.push(new NordicResultSection(result));
                });
            }

            this.getOverallScore = function() {
                return overall;
            }

            this.getPerformanceScore = function() {
                return performance;
            }

            this.getVerbalScore = function() {
                return verbal;
            }

            this.getSection = function(id) {
                var result = null;

                angular.forEach(sections, function(section) {
                    if (id != section.id) {
                        return;
                    }

                    result = section;
                });

                return result;
            }

            this.getSectionScore = function(id){
                var section = this.getSection(id);
                return section ? section.score : null;
            }

            this.getSectionQuestions = function(id) {
                var section = this.getSection(id);
                return section ? section.questions : null;
            }

            this.getQuestions = function() {
                return questions;
            }
        };
    }])

    .factory('NordicResultScore', [function() {
        return function(result) {
            this.result = result.result || 0;
            this.scale = result.scale || 0;
            this.obtained = result.obtained || 0;
            this.potential = result.potential || 0;
            this.percentile = result.percentile || 0;
        }
    }])

    .factory('NordicResultSection', ['NordicResultQuestions', 'NordicResultScore', function(NordicResultQuestions, NordicResultScore) {
        return function(result) {
            this.id = result.id || 'unknown';
            this.score = new NordicResultScore(result.score || {});
            this.questions = new NordicResultQuestions(result.questions || {});
        }
    }])

    .factory('NordicResultQuestions', [function() {
        return function(result) {
            this.total             = result.total             ||   0;
            this.answered          = result.answered          ||   0;
            this.unanswered        = result.unanswered        ||   0;
            this.correct           = result.correct           ||   0;
            this.incorrect         = result.incorrect         ||   0;
            this.percentUnanswered = result.percentUnanswered || 100;
            this.percentAnswered   = result.percentAnswered   ||   0;
            this.percentCorrect    = result.percentCorrect    ||   0;
            this.percentIncorrect  = result.percentIncorrect  ||   0;
        }
    }])

;

})(angular);