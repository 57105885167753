(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityStatisticsPersonalities', ['quizNavigator', 'translator', function(quizNavigator, translator) {
            return {
                templateUrl: 'common/personality/component/personalityStatisticsPersonalities/personalityStatisticsPersonalities.html',
                link: function link(scope, element, attrs) {
                    scope.navigator = quizNavigator;
                    scope.subtype = scope.component.subtype;
                    scope.characters = scope.component.characters || [];
                    for (ii = 0; ii < scope.characters.length; ii++) {
                        if (translator(scope.characters[ii].namemobileId).indexOf('instance') == 0) {
                            scope.characters[ii].namemobileId = scope.characters[ii].namelocalId;
                        }
                    }

                    scope.activeTextIndex = null;
                    scope.textShow = function (index) {
                        if (index === scope.activeTextIndex) {
                            index = null;
                        }
                        scope.activeTextIndex = index;
                    };
                    scope.textIsShowing = function (index) {
                        return scope.activeTextIndex === index;
                    };
                }
            };
        }]);
    }])

;

})(angular);
