(function(angular) {

angular.module('app.common.markdown', ['ngSanitize'])

    .factory('markdown', ['$window', function ($window) {
        if (typeof $window.markdownit == 'undefined') {
            return function(text) {
                return '<p>' + text + '</p>';
            }
        }

        var mark = new $window.markdownit();

        // Override link_open to ensure that all links open in a new window
        var rule = mark.renderer.rules.link_open || function(tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };
        mark.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            tokens[idx].attrPush(['target', '_blank']);

            return rule(tokens, idx, options, env, self);
        };

        return function(text) {
            return mark.render(text);
        };
    }])

    .filter('markdown', ['$sce', 'markdown', function($sce, markdown) {
        return function (text) {
            return $sce.trustAsHtml(markdown(text));
        };
    }])

    .filter('markdownExtract', ['$sce', 'markdown', function($sce, markdown) {
        return function(text) {
            var paragraphs = text ? text.replace(/\r\n|\r/g, '\n').split('\n\n') : [''];

            return $sce.trustAsHtml(markdown(paragraphs[0]));
        }
    }])

    .filter('markdownBR', ['$sce', 'markdown', function($sce, markdown) {
        return function(text) {
            text = text.replace(/[\r\n]+/g, '<br>');
            return $sce.trustAsHtml(text);
        }
    }])
;

})(angular);
