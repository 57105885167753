(function(angular) {

angular.module('app.common.iq')

    .config(['componentProvider', function(provider) {
        provider.register('iqResultTotal', ['quizTrigger', function(quizTrigger) {
            return {
                templateUrl: 'common/iq/component/iqResult/iqResultTotal/iqResultTotal.html',
                link: function link(scope, element, attrs) {
                    scope.header = scope.component.header;
                    scope.result = scope.component.score() ? scope.component.score().result : 0;
                    quizTrigger.requestReportEmail();
                }
            };
        }]);
    }])

;

})(angular);
