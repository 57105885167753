(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityCarousel', ['quizNavigator', function(quizNavigator) {
            return {
                templateUrl: 'common/personality/component/personalityCarousel/personalityCarousel.html',
                link: function link(scope, element, attrs) {
                    scope.lang = document.documentElement.getAttribute('lang');
                    scope.navigator = quizNavigator;
                    scope.subtype = (scope.component.subtype);
                }
            };
        }]);
    }])

;

})(angular);
