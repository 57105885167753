(function (angular) {

    angular.module('app.common.personality', [
        'app.common.translation',
        'app.common.component',
        'app.common.quiz',
        'app.common.environment'
    ])

        .config(['quizBuilderProvider', function (provider) {
            provider.addBuilder('personality', ['personalityBuilder', function (builder) {
                return builder;
            }])
        }])

        .config(['quizStateSerializerProvider', function (provider) {
            provider.addSerializer('personality', ['personalityStateSerializer', function (serializer) {
                return serializer;
            }])
        }])

        .config(['quizResultDeserializerProvider', function (provider) {
            provider.addDeserializer('personality', [
                'personalityResultDeserializer', function (deserializer) {
                    return deserializer;
                }
            ])
        }])

        .factory('personalityStateSerializer', ['quizPaywall', function (paywall) {
            return {
                serialize: function (quiz) {
                    return {
                        user: paywall.getState(),
                        answers: quiz.questions.getState(),
                    }
                },
                deserialize: function (quiz, state) {
                    paywall.setState(state.user || {});
                    quiz.questions.setState(state.answers || []);
                    paywall.flowId = state.id;

                    return true;
                }
            }
        }])

        .factory('personalityResultDeserializer', ['personalityResults', function (results) {
            return function (quiz, resultData) {
                results.setState(resultData || {});

                return true;
            }
        }])

        .factory('personalityBuilder', [
            '$log', 'QuizPage', 'QuizQuestion', 'quizNavigator', 'quizPaywall',
            function ($log, QuizPage, QuizQuestion, navigator, paywall) {

                return function (quiz, data) {
                    data = angular.merge({
                        questions: [],
                        statistics: {
                            flows: 0,
                            answers: 0,
                            characters: [],
                        }
                    }, data);

                    if (data.id === 'job_illvid') {
                       // TODO get test variant data.statement_variant

                        // Intro
                        quiz.pages.add(new QuizPage({
                            id: 'intro',
                            components: [{
                                subtype: 'job',
                                type: 'personalityWrapper', components: [
                                    {type: 'personalityIntro'},
                                    {type: 'personalityCarousel', subtype: 'job'},
                                    {
                                        type: 'personalityStatisticsPersonalities',
                                        subtype: 'job',
                                        characters: data.statistics.characters || []
                                    },
                                    {
                                        type: 'personalityStatisticsParticipation',
                                        flows: data.statistics.flows || 0,
                                        answers: data.statistics.answers || 0
                                    }
                                ]
                            }]
                        }));

                        // Questions
                        angular.forEach(data.questions, function (questionData, index) {
                            var questionIndex = index + 1;
                            var isFirstQuestion = index === 0;
                            var isLastQuestion = questionIndex === data.questions.length;

                            var question = new QuizQuestion({
                                id: questionData.id || null,
                                type: 'personalityQuestion',
                                textId: questionData.textId || 'unknown',
                                choices: questionData.choices || []
                            });

                            var page = quiz.pages.add(new QuizPage({
                                id: question.id,
                                isProgress: true,
                                isSync: isLastQuestion,
                                components: [{
                                    subtype: 'job',
                                    type: 'personalityWrapper',
                                    components: [
                                        {
                                            type: 'personalityProgress',
                                            textId: 'general.question_header',
                                            textData: {
                                                index: questionIndex,
                                                total: data.questions.length
                                            },
                                            progressIndex: index + 1,
                                            progressTotal: data.questions.length
                                        },
                                        {
                                            type: 'personalityQuestion',
                                            question: quiz.questions.add(question)
                                        }
                                    ]
                                }],
                                isCompleted: function () {
                                    return question.isAnswered();
                                }
                            }));

                            // Add buttons to bottom of page to all except the first one
                            if (!isFirstQuestion) {
                                page.components[0].components.push({
                                    type: 'columns',
                                    columns: [
                                        {
                                            width: 6, components: [{
                                                type: 'personalityButton',
                                                textId: 'general.last_question',
                                                icon: 'chevron-left',
                                                click: function () {
                                                    navigator.prev();
                                                }
                                            }]
                                        },
                                        {
                                            width: 6, components: [{
                                                type: 'pull',
                                                direction: 'right',
                                                components: [{
                                                    type: 'personalityButton',
                                                    textId: 'general.restart_quiz',
                                                    icon: 'repeat',
                                                    click: function () {
                                                        quiz.questions.clearState();
                                                        navigator.first();
                                                    }
                                                }]
                                            }]
                                        }
                                    ]
                                })
                            }
                        });

                        var statementVariant = (data['statement_variant'] > 0) ? data['statement_variant'] : '';
                        var waitId = 'wait' + statementVariant;

                        //var waitId = 'wait';

                        var personalityWrapperComponents = [
                            {
                                type: 'header',
                                id: 'general.paywall_header',
                                center: true
                            },
                            {
                                type: 'paragraph',
                                id: 'general.paywall_text',
                                center: true,
                            }
                        ];

                        if (data.locale === 'da' || data.locale === 'no') {

                            var personalityWrapperComponents = [
                                {
                                    type: 'header',
                                    id: 'general.paywall_header',
                                    center: true
                                },
                                {
                                    type: 'paragraph',
                                    id: 'general.paywall_text',
                                    center: true,
                                    bold:true,
                                }
                            ];

                            personalityWrapperComponents.push({
                                type: 'paragraph',
                                id: 'general.paywall_text_second_paragraph',
                                center: true,
                                bold: true
                            }
                            );
                        }

                        personalityWrapperComponents.push({
                            type: 'paywall',
                            variant: data['statement_variant']
                        });

                        // Paywall
                        quiz.pages.add(new QuizPage({
                            id: waitId,
                            isSync: true,
                            components: [{
                                type: 'personalityWrapper', components: personalityWrapperComponents
                            }],
                            isCompleted: function () {
                                return paywall.isValid();
                            }
                        }));

                        // Result
                        quiz.pages.add(new QuizPage({
                            id: 'result',
                            components: [{
                                subtype: 'job',
                                type: 'personalityWrapper', components: [
                                    {
                                        type: 'personalityResultCharacterJob',
                                        subtype: 'job',
                                        paywall: paywall,
                                    },
                                    {type: 'personalityResultScales'},
                                    {
                                        type: 'personalityResultCharacterLike',
                                        subtype: 'job',
                                    },
                                    {
                                        type: 'personalityResultStatistics',
                                        flows: data.statistics.flows || 0
                                    },
                                    {
                                        type: 'personalityResultShopJob',
                                        quiz: quiz,
                                        locale: data.locale
                                    },
                                    {type: 'personalityResultButtonRestart'},
                                ]
                            }]
                        }));
                    }
                    else {
                        // Intro
                        quiz.pages.add(new QuizPage({
                            id: 'intro',
                            components: [{
                                subtype: '',
                                type: 'personalityWrapper', components: [
                                    {type: 'personalityIntro'},
                                    {type: 'personalityCarousel'},
                                    {
                                        type: 'personalityStatisticsParticipation',
                                        flows: data.statistics.flows || 0,
                                        answers: data.statistics.answers || 0
                                    },
                                    {
                                        type: 'personalityStatisticsPersonalities',
                                        characters: data.statistics.characters || []
                                    }
                                ]
                            }]
                        }));

                        // Questions
                        angular.forEach(data.questions, function (questionData, index) {
                            var questionIndex = index + 1;
                            var isFirstQuestion = index === 0;
                            var isLastQuestion = questionIndex === data.questions.length;

                            var question = new QuizQuestion({
                                id: questionData.id || null,
                                type: 'personalityQuestion',
                                textId: questionData.textId || 'unknown',
                                choices: questionData.choices || []
                            });

                            var page = quiz.pages.add(new QuizPage({
                                id: question.id,
                                isProgress: true,
                                isSync: isLastQuestion,
                                components: [{
                                    type: 'personalityWrapper',
                                    components: [
                                        {
                                            type: 'personalityProgress',
                                            textId: 'general.question_header',
                                            textData: {
                                                index: questionIndex,
                                                total: data.questions.length
                                            },
                                            progressIndex: index + 1,
                                            progressTotal: data.questions.length
                                        },
                                        {
                                            type: 'personalityQuestion',
                                            question: quiz.questions.add(question)
                                        }
                                    ]
                                }],
                                isCompleted: function () {
                                    return question.isAnswered();
                                }
                            }));

                            // Add buttons to bottom of page to all except the first one
                            if (!isFirstQuestion) {
                                page.components[0].components.push({
                                    type: 'columns',
                                    columns: [
                                        {
                                            width: 6, components: [{
                                                type: 'personalityButton',
                                                textId: 'general.last_question',
                                                icon: 'chevron-left',
                                                click: function () {
                                                    navigator.prev();
                                                }
                                            }]
                                        },
                                        {
                                            width: 6, components: [{
                                                type: 'pull',
                                                direction: 'right',
                                                components: [{
                                                    type: 'personalityButton',
                                                    textId: 'general.restart_quiz',
                                                    icon: 'repeat',
                                                    click: function () {
                                                        quiz.questions.clearState();
                                                        navigator.first();
                                                    }
                                                }]
                                            }]
                                        }
                                    ]
                                })
                            }
                        });

                        // Paywall
                        quiz.pages.add(new QuizPage({
                            id: 'wait',
                            isSync: true,
                            components: [{
                                type: 'personalityWrapper', components: [
                                    {
                                        type: 'header',
                                        id: 'general.paywall_header',
                                        center: true
                                    },
                                    {
                                        type: 'paragraph',
                                        id: 'general.paywall_text',
                                        center: true
                                    },
                                    {type: 'paywall'}
                                ]
                            }],
                            isCompleted: function () {
                                return paywall.isValid();
                            }
                        }));

                        // Result
                        quiz.pages.add(new QuizPage({
                            id: 'result',
                            components: [{
                                type: 'personalityWrapper', components: [
                                    {type: 'personalityResultCharacter'},
                                    {type: 'personalityResultButtonShareFacebook'},
                                    {type: 'personalityResultScales'},
                                    {type: 'personalityResultCharacterLike'},
                                    {
                                        type: 'personalityResultStatistics',
                                        flows: data.statistics.flows || 0
                                    },
                                    {
                                        type: 'personalityResultButtonReport',
                                        quiz: quiz,
                                        locale: data.locale
                                    },
                                    {type: 'personalityResultButtonRestart'},
                                ]
                            }]
                        }));
                    }

                    return true;
                }
            }])

    ;

})(angular);
