(function(angular) {

    angular.module('app.common.nordic')

        .config(['componentProvider', function(provider) {
            provider.register('nordicQuizBoxHeading', [function() {
                return {
                    templateUrl: 'common/nordic/component/nordicQuizBoxHeading/nordicQuizBoxHeading.html',
                    link: function link(scope) {
                        scope.text = scope.component.text;
                    }
                };
            }]);
        }])

    ;

})(angular);
