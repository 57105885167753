(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultCharacterLike', ['personalityResults', 'translator', function(results, translator) {
            return {
                templateUrl: 'common/personality/component/personalityResultCharacterLike/personalityResultCharacterLike.html',
                link: function link(scope, element, attrs) {
                    scope.subtype = scope.component.subtype;
                    scope.matches = results.getAdditionalMatches();
                    for (ii = 0; ii < scope.matches.length; ii++) {
                        if (translator(scope.matches[ii].character.namemobileId).indexOf('instance') == 0) {
                            scope.matches[ii].character.namemobileId = scope.matches[ii].character.namelocalId;
                        }
                    }
                    scope.activeTextIndex = null;
                    scope.textShow = function (index) {
                        if (index === scope.activeTextIndex) {
                            index = null;
                        }
                        scope.activeTextIndex = index;
                    };
                    scope.textIsShowing = function (index) {
                        return scope.activeTextIndex === index;
                    };

                }
            };
        }]);
    }])

;

})(angular);
