(function(angular) {

angular.module('app.common.iq')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.save_progress_pending_button',
            'general.save_progress_error_button',
            'general.save_progress_return_button',
            'general.save_progress_return_url'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('progressSaveButton', ['quizTrigger', 'quizPaywall', function(quizTrigger, quizPaywall) {

            return {
                templateUrl: 'common/quiz/component/progressSaveButton/progressSaveButton.html',
                link: function link(scope, element, attrs) {
                    scope.state     = 'pending';
                    scope.resumeUrl = '';

                    scope.canSave = function() {
                        return quizPaywall.isValid() && scope.state != 'saving';
                    }

                    scope.save = function() {
                        if (scope.state == 'saving') {
                            return;
                        }

                        scope.state = 'saving';

                        quizTrigger.requestResumeEmail().then(
                            function success(url) {
                                scope.state = 'saved';
                                scope.resumeUrl = url;
                            },
                            function error() {
                                scope.state = 'error';
                            }
                        );
                    }
                }
            };
        }]);
    }])
;

})(angular);
