(function(angular) {

angular.module('app.common.quiz')

    .factory('Quiz', ['QuizQuestionList', 'QuizPageList', function(QuizQuestionList, QuizPageList) {

        return function() {
            var id          = null;
            var type        = null;
            var stateId     = null;
            var currentPage = null;

            this.pages     = new QuizPageList;
            this.questions = new QuizQuestionList;

            this.clear = function() {
                id          = null;
                type        = null;
                stateId     = null;
                currentPage = null;

                this.pages     = new QuizPageList;
                this.questions = new QuizQuestionList;
            }

            this.getId = function() {
                return id;
            }

            this.setId = function(quizId) {
                id = quizId;
            }

            this.getType = function() {
                return type || 'default';
            }

            this.setType = function(quizType) {
                type = quizType;
            }

            this.getStateId = function() {
                return stateId;
            }

            this.setStateId = function(quizStateId) {
                stateId = quizStateId;
            }

            this.hasCurrentPage = function() {
                return currentPage != null;
            }

            this.getCurrentPage = function() {
                return currentPage;
            }

            this.setCurrentPage = function(page) {
                currentPage = page;
            }
        }
    }])

    // QuizQuestion factory which creates a question of the proper
    // subtype, resolved using quizQuestionTypeResolver.
    //
    // A QuizQuestion must support the interface:
    //
    //  * getState()       -> object
    //  * setState(object) ->   bool
    //  * clearState()     ->   void
    //  * isAnswered()     ->   bool
    //  * isCorrect()      ->   bool
    //
    // Default implementations are applied unless the subtype contains them.
    //
    .factory('QuizQuestion', ['$log', 'quizQuestionTypeResolver', function($log, resolver) {
        var nextId = 1;

        return function(options) {
            this.id = options.id || 'question-' + (nextId++);

            this.type = options.type || 'unknown';

            this.getState = function() {
                return null;
            };

            this.setState = function() {
                return true;
            };

            this.clearState = function() {
            };

            this.isAnswered = function() {
                return false;
            };

            this.isCorrect = function() {
                return false;
            }

            var func = resolver(this.type);

            if (!func) {
                $log.error('could not resolve type constructor for question type: ' + this.type);
                return
            }

            func.apply(this, [options]);
        }
    }])

    // Resolver for question specializations
    .provider('quizQuestionTypeResolver', function() {
        var types = {};

        this.registerType = function(type, func) {
            types[type] = func;
        };

        this.$get = [function() {
            return function(type) {
                return types.hasOwnProperty(type) ? types[type] : null;
            }
        }]
    })

    .factory('QuizQuestionList', ['$log', function($log) {
        return function() {

            var questions = [];

            // TODO: ensure that all questions have the following properties
            // * a unique id
            // * a getState method which will be used to serialize the question state
            // * a setState method which will be used to unserialize the question state
            // * a isAnswered method that will return if the question has been answered so the quiz can proceed
            this.add = function(question) {
                questions.push(question);

                return question;
            }

            this.get = function(id) {
                for (var i = 0; i < questions.length; ++i) {
                    if (questions[i].id != id) {
                        continue;
                    }

                    return questions[i];
                }

                return null;
            }

            this.getAll = function() {
                return questions;
            }

            this.getState = function() {
                var state = {};

                for (var i = 0; i < questions.length; ++i) {
                    var questionState = questions[i].getState();

                    if (!questionState) {
                        continue;
                    }

                    state[questions[i].id] = questionState;
                }

                return state;
            }

            this.setState = function(state) {
                this.clearState();

                angular.forEach(state, function(questionState, questionId) {
                    var question = this.get(questionId);

                    if (!question) {
                        $log.debug('error while applying state, quiz does not have a question with id: ', questionId)
                        return;
                    }

                    if (!question.setState(questionState)) {
                        $log.debug('failed to apply state for question with id: ', questionId)
                        return
                    }

                }, this);
            }

            this.clearState = function() {
                angular.forEach(questions, function(question) {
                    question.clearState();
                });
            }
        }
    }])

    .factory('QuizPage', [function() {
        var nextId = 1;

        return function(options) {
            options = options || {};

            this.id   = options.id || 'page-' + (nextId++);
            this.prev = null;
            this.next = null;

            this.components = [];
            this.isProgress = options.isProgress || false;
            this.isSync     = options.isSync || false;

            this.isCompleted = function() {
                return true;
            }

            if (angular.isArray(options.components)) {
                this.components = options.components;
            }

            if (angular.isFunction(options.isCompleted)) {
                this.isCompleted = options.isCompleted;
            }
        }
    }])

    .factory('QuizPageList', function() {
        return function() {
            var pages = [];

            // TODO: ensure that all questions have the following properties
            // * a unique id
            // * a boolean flag called isProgress that determines if the page counts towards quiz progression
            // * a array called components which describes the page content
            this.add = function(page) {
                var prev = pages.length > 0 ? pages[pages.length-1] : null;

                page.next = null;
                page.prev = prev;

                if (prev) {
                    prev.next = page;
                }

                pages.push(page);

                return page;
            }

            this.exists = function(id) {
                return this.get(id) != null;
            }

            this.isReachable = function(id) {
                for (var i = 0; i < pages.length; i++) {
                    if (pages[i].id == id) {
                        return true;
                    }

                    if (!pages[i].isCompleted()) {
                        return false;
                    }
                }

                return false;
            }

            this.getAll = function() {
                return pages;
            }

            this.get = function(id) {
                for (var i = 0; i < pages.length; ++i) {
                    if (pages[i].id != id) {
                        continue;
                    }

                    return pages[i];
                }

                return null;
            }

            this.getFirst = function() {
                return pages.length > 0 ? pages[0] : null;
            }

            this.getLastReachable = function() {
                for (var i = 0; i < pages.length; ++i) {
                    if (!pages[i].isCompleted()) {
                        return pages[i];
                    }

                    if (i == pages.length-1) {
                        return pages[i];
                    }
                }

                return this.getFirst();
            }

            this.clearAll = function () {
                pages = [];

                return pages;
            }
        }
    })

    .factory('QuizPaywall', [function() {
        return function() {

            var firstName   = '';
            var lastName    = '';
            var email       = '';
            var phoneNumber = '';
            var birth = '';
            var userInterests = {};
            var variant = 0;

            this.getFirstName = function() {
                return firstName;
            };

            this.setFirstName = function(value) {
                firstName = value;
            };

            this.getLastName = function() {
                return lastName;
            };

            this.setLastName = function(value) {
                lastName = value;
            };

            this.getEmail = function() {
                return email;
            };

            this.setEmail = function(value) {
                email = value;
            };

            this.getPhoneNumber = function() {
                return phoneNumber;
            };

            this.setPhoneNumber = function(value) {
                phoneNumber = value;
            };
            this.getBirth = function() {
                return birth;
            };

            this.setBirth = function(value) {
                birth = value;
            };

            this.getUserInterests = function() {
                return userInterests;
            };

            this.setUserInterests = function(value) {
                userInterests = value;
            };

            this.getVariant = function() {
                return variant;
            };

            this.setVariant = function(value) {
                variant = value;
            };

            this.isValid = function() {
                if (!firstName) {
                    return false;
                }

                if (!lastName) {
                    return false;
                }

                if (!email) {
                    return false;
                }

                if (!phoneNumber) {
                    return false;
                }

                // if (!birth) {
                //     return false;
                // }

                return true;
            };

            this.isValidCheckboxes = function () {
                if ((window.locale == 'da' || window.locale == 'sv') && variant == 2) {
                    for (var key in userInterests){
                        if (userInterests[key]) {
                            return true;
                        }
                    }
                    return false;
                }
            };

            this.getState = function() {
                return {
                    firstName:   firstName,
                    lastName:    lastName,
                    email:       email,
                    phoneNumber: phoneNumber,
                    birth: birth,
                    userInterests: userInterests
                };
            };

            this.setState = function(state) {
                this.setFirstName(state.firstName || '');
                this.setLastName(state.lastName || '');
                this.setEmail(state.email || '');
                this.setPhoneNumber(state.phoneNumber || '');
                this.setBirth(state.birth || '');
                this.setUserInterests(state.userInterests || []);
            }
        }
    }])

;

})(angular);