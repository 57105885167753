(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultShopJob', [
            'personalityResults',
            'translator',
            'quizTrigger',
            function(results, translator, quizTrigger) {
                return {
                    templateUrl:
                        'common/personality/component/personalityResultShopJob/personalityResultShopJob.html',
                    link: function link(scope, element, attrs) {
                        scope.locale = scope.component.locale;
                        quizTrigger.requestReportEmail();
                    }
                };
        }]);
    }])
;

})(angular);
