(function(angular) {

angular.module('app.common.component', ['app.common.translation', 'app.common.markdown'])

    .provider('componentDirectiveResolver', [function() {
        var types = {};
        var fallback = 'component-unknown';

        this.registerFallback = function(directive) {
            fallback = directive;
        };

        this.registerType = function(type, directive) {
            types[type] = directive;
        };

        this.$get = [function() {
            return function(type) {
                if (!types.hasOwnProperty(type)) {
                    return fallback;
                }

                return types[type];
            };
        }];
    }])

    .provider('component', ['$compileProvider', '$injector', 'componentDirectiveResolverProvider', function($compileProvider, $injector, resolverProvider) {

        function namify(name) {
            return 'component' + name.charAt(0).toUpperCase() + name.slice(1);
        }

        function typify(name) {
            return namify(name).replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        }

        function baseDirective(directiveName, directiveFunc) {
            return function() {
                var base = {
                    restrict: 'A',
                    replace:  true,
                    scope: {
                        'component': '=' + directiveName
                    }
                };

                return angular.extend(base, directiveFunc.apply(null, arguments));
            }
        }

        this.register = function(name, directive) {
            directiveName = namify(name);                // myThing -> componentMyThing
            directiveType = typify(name);                // myThing -> component-my-thing
            directiveFunc = angular.isArray(directive) ? directive[directive.length - 1] : directive;

            // Create a wrapper which will setup the a injectable directive with proper defaults
            directiveWrapper = angular.copy($injector.annotate(directive));
            directiveWrapper.push(baseDirective(directiveName, directiveFunc));

            $compileProvider.directive(directiveName, directiveWrapper);
            resolverProvider.registerType(name, directiveType);
        }

        this.$get = [function() {
            return {}
        }];
    }])

    .directive('component', ['$compile', '$log', 'componentDirectiveResolver', function($compile, $log, resolver) {
        return {
            restrict: 'A',
            replace: true,
            terminal: true,
            scope: {
                'component': '='
            },
            link: function link(scope, element, attrs) {
                if (!scope.component) {
                    $log.error('[component] directive invoked without a scope component');
                    scope.component = {
                        type: 'missingComponent'
                    };
                }

                if (!scope.component.hasOwnProperty('type')) {
                    $log.error('[component] component has no type, defaulting to missing');
                    scope.component.type = 'missingType';
                }

                var directive = resolver(scope.component.type);

                // http://stackoverflow.com/questions/19224028/add-directives-from-directive-in-angularjs
                // http://stackoverflow.com/questions/16432198/passing-variable-to-angular-directive
                element.attr(directive, 'component');
                element.removeAttr("component");
                element.removeAttr("data-component");
                $compile(element)(scope);
            }
        };
    }])

;

})(angular);
