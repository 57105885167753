(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityButton', [function() {
            return {
                templateUrl: 'common/personality/component/personalityButton/personalityButton.html',
                link: function link(scope, element, attrs) {
                    scope.textId   = scope.component.textId   || null;
                    scope.textData = scope.component.textData || {};
                    scope.icon     = scope.component.icon     || null;
                    scope.click    = scope.component.click    || function() {};
                }
            };
        }]);
    }])

;

})(angular);
