(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('list', [function() {
            return {
                templateUrl: 'common/component/list/list.html',
                link: function link(scope, element, attrs) {
                    scope.items = scope.component.items;
                }
            };
        }]);
    }])

;

})(angular);
