(function(angular) {

angular.module('app.common.nordic')

    .config(['componentProvider', function(provider) {
        provider.register('nordicResultSection', [function() {
            return {
                templateUrl: 'common/nordic/component/nordicResult/nordicResultSection/nordicResultSection.html',
                link: function link(scope, element, attrs) {
                    scope.header = scope.component.header;
                    scope.text = scope.component.text;
                    scope.scale = scope.component.score() ? scope.component.score().scale : 0;
                }
            };
        }]);
    }])

;

})(angular);
