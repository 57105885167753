(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultButtonRestart', [function() {
            return {
                templateUrl: 'common/personality/component/personalityResultButtonRestart/personalityResultButtonRestart.html',
                link: function link(scope, element, attrs) {
                    
                }
            };
        }]);
    }])
;

})(angular);
