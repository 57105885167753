(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('header', [function() {
            return {
                templateUrl: 'common/component/header/header.html',
                link: function link(scope, element, attrs) {
                    scope.id     = scope.component.id;
                    scope.data   = scope.component.data;
                    scope.center = true === (scope.component.center || false);
                }
            };
        }]);
    }])
;

})(angular);
