(function(angular) {

angular.module('app.common.environment', [])

    .provider('environment', [function() {
        var environment = {};

        this.set = function(key, value) {
            environment[key] = value;
        }

        this.$get = [function() {
            return function(key, defaultValue) {
                if (!environment.hasOwnProperty(key)) {
                    return defaultValue;
                }

                return environment[key];
            };
        }]
    }])

;

})(angular);

