(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicIcon', [function () {
                return {
                    templateUrl: 'common/nordic/component/nordicIcon/nordicIcon.html',
                    link: function link(scope, element, attrs) {
                        scope.iconClass = scope.component.subject ? 'icon--' + scope.component.subject : false;
                    }
                };
            }]);
        }]);
})(angular);