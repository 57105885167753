(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicQuizBoxImage', [function () {
                return {
                    templateUrl: 'common/nordic/component/nordicQuizBoxImage/nordicQuizBoxImage.html',
                    link: function link(scope) {
                        scope.imgName = scope.component.imgName;
                        scope.altImage = scope.component.altImage;
                    }
                };
            }]);
        }])

    ;

})(angular);
