(function (angular) {

    angular.module('app.common.quiz')

        .config(['translationProvider', function (provider) {
            provider.declareMessageKeys([
                'general.paywall_register_with_facebook_button',
                'general.paywall_field_first_name_label',
                'general.paywall_field_last_name_label',
                'general.paywall_field_email_label',
                'general.paywall_field_phone_number_label',
                'general.paywall_field_birth_label',
                'general.paywall_field_accept_label',
                'general.paywall_permission_text',
                'general.paywall_submit',
                'general.paywall_field_accept_label_error',
            ]);
        }])

        .config(['componentProvider', function (provider) {
            provider.register('paywall', ['quizNavigator', 'quizPaywall', 'environment', 'facebook', '$http', 'quiz', 'sha256', function (navigator, paywall, environment, facebook, $http, quiz, sha256) {
                return {
                    templateUrl: 'common/quiz/component/paywall/paywall.html',
                    link: function link(scope, element, attrs) {

                        var hostnamesArr = [
                            'karriereguiden.illvid.dk',
                            'karriarguiden.illvet.se',
                            'karriereguiden.illvit.no',
                            'uraopas.tieku.fi',
                        ];
                        scope.removeFacebookButton = checkInHostname(hostnamesArr, window.location.hostname);
                        var contestHostArr = [
                            'karriereguiden.illvid.dk',
                            'karriereguiden.illvit.no',
                        ];
                        scope.showContest = checkInHostname(contestHostArr, window.location.hostname);
                        ;

                        scope.lang = document.documentElement.getAttribute('lang');
                        scope.firstName = paywall.getFirstName();
                        scope.lastName = paywall.getLastName();
                        scope.email = paywall.getEmail();
                        scope.phoneNumber = paywall.getPhoneNumber();
                        scope.birth = paywall.getBirth();
                        scope.userInterests = paywall.getUserInterests();
                        scope.facebookPhoneReq = false;
                        scope.accept = false;
                        scope.debug = environment('debug', false);
                        scope.variant = scope.component.variant || 0;
                        scope.interests = [
                            'history',
                            'handmade',
                            'health',
                            'housing',
                        ];
                        scope.hiddenInterests = [
                            'food',
                            'technology',
                            'fashion',
                            'cars',
                            'travel',
                            'photo',
                            'economy',
                            'entertainment'
                        ];
                        scope.quizType = quiz.getType();
                        paywall.setVariant(scope.variant);

                        $http.get('/variantsCounter/' + scope.variant).then(function (resp) {
                            console.log(resp.data);
                        });

                        function checkInHostname(hostnamesArr, currentHost) {
                            for (var i = 0; i < hostnamesArr.length; i++) {
                                if (currentHost.indexOf(hostnamesArr[i]) > -1) {
                                    return true;
                                }
                            }
                            return false;
                        };


                        /**
                         * Push leads from Quizes
                         *
                         */
                        scope.newsletterSignups = function newsletterSignups() {
                            var currentHost = window.location.hostname;
                            var pushToUrl = "";
                            var Zapier_sourcecode = "";

                            var data = new FormData();
                            data.append('emailaddress', scope.email);
                            data.append('firstName', scope.firstName);
                            data.append('lastName', scope.lastName);
                            data.append('optin_phone', scope.phoneNumber);

                            var humanDate = null;
                            if (scope.birth){
                                var humanDate = timestampToDate(scope.birth);
                                data.append('dateOfBirth', humanDate) ;
                            }

                            /**
                             * IQ quizes
                             */
                            if (checkInHostname(['iq-test.illvid.dk'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/881/1/EOGswW';
                                data.append('Zapier_sourcecode', 199020);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['iq-test.illvet.se'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/882/1/agKP9Y';
                                data.append('Zapier_sourcecode', 299020);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['iqtest.illvit.no'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/883/1/IO4CBy';
                                data.append('Zapier_sourcecode', 399020);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['ao-testi.tieku.fi'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/884/1/5Zqg8W';
                                data.append('Zapier_sourcecode', 499020);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['iq-test.wibnet.nl'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/1003/1/X117ZC';
                                data.append('Zapier_sourcecode', 899020);
                                scope.pushSignUp(pushToUrl, data);
                            }

                            /**
                             * Carrier quizes
                             */
                            else if (checkInHostname(['karriereguiden.illvid.dk'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/881/1/EOGswW';
                                data.append('Zapier_sourcecode', 198589);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['karriarguiden.illvet.se'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/882/1/agKP9Y';
                                data.append('Zapier_sourcecode', 298589);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['karriereguiden.illvit.no'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/883/1/IO4CBy';
                                data.append('Zapier_sourcecode', 398589);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['uraopas.tieku.fi'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/884/1/5Zqg8W';
                                data.append('Zapier_sourcecode', 498589);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['karriereguiden.wibnet.nl'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/1003/1/X117ZC';
                                data.append('Zapier_sourcecode', 898589);
                                scope.pushSignUp(pushToUrl, data);
                            }

                            /**
                             * History quizes
                             */
                            else if (checkInHostname(['personlighedstest.historienet.dk'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/889/1/K3wakF';
                                data.append('Zapier_sourcecode', 198464);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['personlighetstest.varldenshistoria.se'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/890/1/NOXhQh';
                                data.append('Zapier_sourcecode', 298464);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['personlighetstest.historienet.no'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/891/1/v2SCro';
                                data.append('Zapier_sourcecode', 398464);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['historiatesti.historianet.fi'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/892/1/mGP38W';
                                data.append('Zapier_sourcecode', 498464);
                                scope.pushSignUp(pushToUrl, data);
                            }
                            else if (checkInHostname(['persoonlijkheidstest.historianet.nl'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/1002/1/f9DHRt';
                                data.append('Zapier_sourcecode', 898464);
                                scope.pushSignUp(pushToUrl, data);
                            }
                        }
                        scope.pushSignUp = function (pushToUrl, data){
                            var xhr = new XMLHttpRequest();
                            xhr.open('POST', pushToUrl, true);
                            xhr.onload = function () {
                                // console.log(this.responseText);
                            };
                            xhr.send(data);
                        }

                        scope.loginFacebook = function () {
                            facebook.user().then(
                                function success(user) {
                                    scope.firstName = user.firstName;
                                    scope.lastName = user.lastName;
                                    scope.email = user.email;
                                    scope.birth = user.birth;
                                    if (!scope.phoneNumber) {
                                        scope.facebookPhoneReq = true;
                                    }
                                }
                            );
                        };

                        scope.birthFix = function (birth) {
                            var unix = (+new Date(birth)) / 1000;
                            var offset = new Date(birth).getTimezoneOffset();
                            scope.birth = unix - (offset * 60);
                        };

                        scope.submitForm = function (valid) {

                            if (!valid) {
                                return;
                            }

                            paywall.setFirstName(scope.firstName);
                            paywall.setLastName(scope.lastName);
                            paywall.setEmail(scope.email);
                            paywall.setPhoneNumber(scope.phoneNumber);
                            paywall.setBirth(scope.birth);
                            paywall.setUserInterests(scope.userInterests);
                            paywall.setVariant(scope.variant);

                            if (!paywall.isValid()) {
                                return;
                            }

                            var userId = sha256.convertToSHA256(scope.email);
                            dataLayer.push({
                                "event": "newsletterSubscribeSubmit",
                                "newsletterSubscriptionFormat": "between questions",
                                "userId": userId
                            });

                            dataLayer.push({
                                "event": "newsletterSubscribeSuccess",
                                "newsletterSubscriptionFormat": "between questions",
                                'userId': userId,
                            });

                            // SignUp
                            scope.newsletterSignups();

                            navigator.next();

                        };

                        scope.debugFill = function () {
                            if (!scope.debug) {
                                return;
                            }

                            if (!faker) {
                                return;
                            }

                            scope.firstName = faker.name.firstName();
                            scope.lastName = faker.name.lastName();
                            scope.email = faker.internet.email();
                            scope.phoneNumber = 12345678; //faker.phone.phoneNumber();
                            scope.birth = faker.date.past();
                            scope.accept = true;
                        };

                        scope.isValidCheckboxes = function () {
                            return paywall.isValidCheckboxes();
                        }
                    }
                };
            }]);
        }])
    ;

timestampToDate = function (birth) {
    birth = birth * 1000;
    var todate=("0" + new Date(birth).getDate()).slice(-2)
    var tomonth=("0" + (new Date(birth).getMonth() + 1)).slice(-2);
    var toyear = new Date(birth).getFullYear();

    return toyear + '-' + tomonth  + '-' + todate ;
}

})(angular);

