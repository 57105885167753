(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicCategoryItem', ['$rootScope', '$http', 'nordicTestBuilder', function ($rootScope) {
                return {
                    templateUrl: 'common/nordic/component/nordicCategoryItem/nordicCategoryItem.html',
                    link: function link(scope) {
                         scope.iconClass = scope.component.subject ? 'icon--' + scope.component.subject : false;
                        scope.title = scope.component.title;
                        scope.description = scope.component.description;
                        scope.toCategory = function () {
                            window.localStorage.clear(); //clear all local storage
                            localStorage.setItem("nordicSubject", scope.component.subject_id);
                            $rootScope.$emit('quizNavigateToPage', scope.component.subject_id);
                            $rootScope.$emit('quizLoad', scope.component.subject_id);
                        }
                    }
                };
            }]);
        }]);
})(angular);