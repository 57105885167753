(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicQuestionTimedTextChoice', ['$interval', 'quizProgress', 'quizNavigator', '$rootScope', function ($interval, quizProgress, quizNavigator, $rootScope) {
                return {
                    templateUrl: 'common/nordic/component/nordicQuestionTimedTextChoice/nordicQuestionTimedTextChoice.html',
                    link: function link(scope, element, attrs) {
                        scope.question = scope.component.question;
                        scope.seconds = scope.question.seconds;
                        scope.offset = 125;
                        scope.questionsPassed = scope.component.questionsPassed;
                        scope.progressBar = scope.component.progressBar;
                        scope.percentage = quizProgress.getPercentage();
                        scope.subjects = scope.component.subjects;
                        scope.activeSubject = scope.component.activeSubject;

                        switch (true) {
                            case scope.percentage < 25:
                                scope.activeSubject = scope.subjects.a;
                                break;
                            case scope.percentage < 50 && scope.percentage > 24:
                                scope.activeSubject = scope.subjects.b;
                                break;
                            case scope.percentage < 75 && scope.percentage > 49:
                                scope.activeSubject = scope.subjects.c;
                                break;
                            case scope.percentage > 74:
                                scope.activeSubject = scope.subjects.d;
                                break;
                        }

                        scope.select = function (choice) {
                            if (scope.question.getTimedOut()) {
                                quizNavigator.next();
                            }
                            scope.question.setSelection(choice.id);

                            dataLayer.push({
                                "event": "questionAnswer",
                                "questionNo": choice.id,
                                "questionName": choice.textId
                            });

                            quizNavigator.next();
                        };

                        scope.isSelected = function (choice) {
                            return scope.question.getSelection() === choice.id;
                        };

                        scope.isTimedOut = function () {
                            return scope.question.getTimedOut();
                        };

                        var ticker = $interval(null, 1000, scope.seconds);

                        ticker.then(function () {
                            // This is doing nothing
                            // scope.question.setTimedOut(true);
                            // scope.offset = 125;
                        }, function () {
                            // nothing here
                        }, function () {
                            scope.seconds = scope.seconds > 0 ? scope.seconds - 1 : 0;
                            scope.offset = 125 - scope.seconds * (125 / scope.question.seconds);

                            if (scope.seconds === 0) {
                                scope.question.setTimedOut(true);
                                $interval.cancel(ticker);
                                quizNavigator.next();
                            }
                        });

                        scope.$on('$destroy', function () {
                            $interval.cancel(ticker);
                        });
                    }
                };
            }]);
        }])
    ;

})(angular);
