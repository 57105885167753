(function(angular) {

angular.module('app.common.personality')

    .factory('personalityResults', ['PersonalityResults', function(PersonalityResults) {
        return new PersonalityResults;
    }])

    .factory('PersonalityResults', [function() {
        return function() {

            var trait   = '';
            var scales  = [];
            var matches = [];
            var same_character_count = null;
            var same_trait_count = null;

            this.setState = function(state) {
                trait   = state.trait || '';
                scales  = [];
                matches = [];
                same_character_count = state.same_character_count;
                same_trait_count = state.same_trait_count;

                angular.forEach(state.scales, function(data, id) {
                    lowerPercent = Math.floor((1.0 - data.value) * 100);
                    upperPercent = 100 - lowerPercent;

                    scales.push({
                        id: data.id,
                        lower: data.lower,
                        upper: data.upper,
                        value: data.value,
                        dial: upperPercent / 100,
                        lowerPercent: lowerPercent,
                        upperPercent: upperPercent
                    });
                });

                angular.forEach(state.matches, function(data) {
                    matches.push({
                        percent:   data.percent,
                        character: data.character
                    })
                });

            }

            this.getSameCharacterCount = function() {
                return same_character_count;
            }

            this.getSameTraitCount = function() {
                return same_trait_count;
            }

            this.getTrait = function() {
                return trait;
            }

            this.getScales = function() {
                return scales;
            }

            this.getBestMatch = function() {
                if (matches.length == 0) {
                    return null;
                }

                return matches[0];
            }

            this.getAdditionalMatches = function() {
                return matches.slice(1, matches.length);
            }
        }
    }])

;

})(angular);
