(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('sections', [function() {
            return {
                templateUrl: 'common/component/sections/sections.html',
                link: function link(scope, element, attrs) {
                    scope.sections = scope.component.sections;
                    scope.active = scope.component.active;
                }
            };
        }]);
    }])

;

})(angular);
