(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicCategoryList', [function () {
                return {
                    templateUrl: 'common/nordic/component/nordicCategoryList/nordicCategoryList.html',
                    link: function link(scope, element, attrs) {
                        scope.components = scope.component.components;
                    }
                };
            }]);
        }])

    ;

})(angular);