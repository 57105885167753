(function(angular) {

angular.module('app.common.personality')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.share_facebook_button',
            'general.share_facebook_link',
            'general.share_facebook_image',
            'general.share_facebook_result_section_text'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultCharacterJob', [
            'personalityResults',
            'facebook',
            'translator',
            function(results, facebook, translator) {
                return {
                    templateUrl: 'common/personality/component/personalityResultCharacterJob/personalityResultCharacterJob.html',
                    link: function link(scope, element, attrs) {
                        scope.lang    = document.documentElement.getAttribute('lang');
                        scope.match   = results.getBestMatch();
                        scope.subtype = scope.component.subtype;
                        
                        var url = window.location.protocol + '//' + window.location.host;
                        var image_url = scope.match.character.image;

                        var params = {
                            name:   translator(scope.match.character.namelocalId),
                            firstName: scope.component.paywall.getFirstName(),
                            percent: scope.match.percent,
                        };

                        scope.params = params;
                        fbq('track', 'CompleteRegistration');

                        caption = 'this is caption';
                        description = "this is description. Linebreak not allowed.";
                        scope.share = function() {
                            var data = {
                                link:    url,
                                picture: image_url,
                                name:    translator('general.share_facebook_title', params),
                                description: translator('general.share_facebook_description'),
                                caption: translator('general.share_facebook_caption'),
                            };

                            facebook.share(data).then(
                                function success(result) {
                                    dataLayer.push({
                                        "event": "socialShare",
                                        "socialPlatform": "Facebook"
                                    });
                                    //console.log(result);
                                },
                                function error(reason) {
                                    //console.error(reason);
                                }
                            )
                        }
                    }
                };
        }]);
    }])
;

})(angular);
