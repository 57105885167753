(function(angular) {

angular.module('app.common.iq')

    .config(['componentProvider', function(provider) {
        provider.register('iqResultButtonSave', ['quizTrigger', function(quizTrigger) {
            return {
                templateUrl: 'common/iq/component/iqResult/iqResultButtonSave/iqResultButtonSave.html',
                link: function link(scope, element, attrs) {
                    scope.state = 'pending';

                    scope.save = function() {
                        if (scope.state == 'saving') {
                            return;
                        }

                        scope.state = 'saving';

                        quizTrigger.requestReportEmail().then(
                            function success(url) {
                                scope.state = 'saved';
                            },
                            function error() {
                                scope.state = 'error';
                            }
                        );
                    }
                }
            };
        }]);
    }])

;

})(angular);
