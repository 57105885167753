(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('html', ['$sce', function($sce) {
            return {
                templateUrl: 'common/component/html/html.html',
                link: function link(scope, element, attrs) {
                    scope.html = $sce.trustAsHtml(scope.component.html);
                }
            };
        }]);
    }])

;

})(angular);
