(function(angular) {

angular.module('app.section.quiz', ['ui.router', 'app.common.translation', 'app.common.quiz', 'app.common.iq', 'app.common.personality', 'app.common.component', 'app.common.nordic'])

    .config(['quizApiProvider', function (quizApiProvider) {
        quizApiProvider.setBaseUrl('/api');
    }])

    .config(['$stateProvider', function($stateProvider) {
        $stateProvider
            .state('quizBootstrap', {
                url: '/bootstrap',
                templateUrl: 'section/quiz/template/loader.html',
                controller: 'QuizBoostrapController'
            })
            .state('quizSynchronize', {
                url: '/synchronize',
                templateUrl: 'section/quiz/template/loader.html',
                controller: 'QuizSynchronizeController'
            })
            .state('quizPage', {
                url: '/:pageId',
                controller: 'QuizPageController',
                templateUrl: 'section/quiz/template/page.html',
                params: {
                    pageId: { squash: true, value: null }
                },
                resolve: {
                    loaded: ['$q', 'sentinel', function($q, sentinel) {
                        if (!sentinel.isQuizLoaded()) {
                            return $q.reject({
                                quizNotLoaded: true
                            });
                        }

                        return true;
                    }],
                    page: ['$stateParams', '$q', 'loaded', 'quiz', function($stateParams, $q, loaded, quiz) {
                        var pageId = $stateParams.pageId;

                        // Default to first page
                        if (!pageId) {
                            return quiz.pages.getFirst();
                        }

                        // Check for non existent pages
                        if (!quiz.pages.exists(pageId)) {
                            return $q.reject({
                                quizNoSuchPageError: true,
                                pageId: pageId
                            });
                        }

                        // Check if page is reachable at the current time
                        if (!quiz.pages.isReachable(pageId)) {
                            return $q.reject({
                                quizPageUnreachableError: true,
                                pageId: pageId
                            });
                        }

                        return quiz.pages.get(pageId);
                    }]
                },
                onEnter: ['quiz', 'page', function(quiz, page) {
                    quiz.setCurrentPage(page);
                }],
                onExit: ['quiz', 'page', 'quizApi', 'quizStateSerializer', function(quiz, page, quizApi, quizStateSerializer) {
                    quiz.setCurrentPage(null);
                }]
            })
        ;
    }])

    // Quiz events
    .run(['$rootScope', '$state', 'quiz', 'quizApi', function ($rootScope, $state, quiz, quizApi) {

        // Handle navigation requests from the quiz
        $rootScope.$on('quizNavigateToPage', function(event, pageId) {
            $state.go('quizPage', { pageId: pageId });
        });

        // Handle resume links from the quiz
        $rootScope.$on('quizRequestResumeEmail', function(event, deferred) {
            var stateId = quiz.getStateId();

            if (!stateId) {
                deferred.reject('no state');
                return;
            }

            // TODO: don't resolve with url
            quizApi.trigger.resume(stateId).then(
                function success(data) {
                    deferred.resolve(data.url);
                },
                function error(reason) {
                    deferred.reject(reason);
                }
            );
        });

        // Handle resume links from the quiz
        $rootScope.$on('quizRequestReportEmail', function(event, deferred) {
            var stateId = quiz.getStateId();

            if (!stateId) {
                deferred.reject('no state');
                return;
            }

            // TODO: don't resolve with url
            quizApi.trigger.report(stateId).then(
                function success(data) {
                    deferred.resolve(data.url);
                },
                function error(reason) {
                    deferred.reject(reason);
                }
            );
        });

        // Handle resume links from the quiz
        $rootScope.$on('quizRequestReportPage', function(event, deferred) {
            var stateId = quiz.getStateId();

            if (!stateId) {
                deferred.reject('no state');
                return;
            }

            quizApi.trigger.report(stateId).then(
                function success(data) {
                    deferred.resolve(data.url);
                },
                function error(reason) {
                    deferred.reject(reason);
                }
            );
        });

    }])

    // UI-router events
    .run(['$rootScope', '$log', '$state', 'redirect', 'quiz', function ($rootScope, $log, $state, redirect, quiz) {

        // Trigger a synchronize after each page that requires it
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            // TODO: i'm not to happy about this check
            if (toState.name != 'quizPage') {
                return;
            }

            // Check if the page we are going to requires synchronization
            var page = quiz.getCurrentPage();
            if (!page || !page.isSync) {
                return;
            }

            // Stop the page transition
            event.preventDefault();

            // Remember where were we going and redirect to synchronize
            redirect.setName(toState.name).setParams(toParams);
            $state.go('quizSynchronize');
        });

        // Handle routing errors related to bootstrapping and page routing
        $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
            if (error.quizNotLoaded) {
                redirect.setName(toState.name).setParams(toParams);
                $state.go('quizBootstrap');

                return;
            }

            if (error.quizNoSuchPageError) {
                $state.go('quizPage');
                return;
            }

            if (error.quizPageUnreachableError) {
                var page = quiz.pages.getLastReachable();

                if (!page) {
                    $state.go('quizPage');
                    return;
                }

                $state.go('quizPage', { pageId: page.id });
                return;
            }

            $log.error('unhandled $stateChangeError with error:', error);
        });
    }])

    .controller('QuizBoostrapController', ['$scope', '$q', '$state', 'sentinel', 'translation', 'quiz', 'quizApi', 'quizBuilder', 'quizStateSerializer', 'quizResultDeserializer', 'redirect', function($scope, $q, $state, sentinel, translation, quiz, quizApi, quizBuilder, quizStateSerializer, quizResultDeserializer, redirect) {
        $scope.error = null;

        sentinel.setQuizLoaded(false);
        quiz.clear();

        // Bootstrap process:
        //  * Load translation data from the server
        //  * Load the quiz data from the server
        //  * Create the quiz using the registered builder
        //  * Load the state data from the server
        //  * Apply the state to the quiz using a registered deserializer
        //  * Load the result data from the server
        //  * Apply the result data using a registered deserializer
        //  * Return to the quiz page that triggered to bootstrap (or the last reachable one)
        quizApi.translation.get().then(
            function success(translationData) {
                translation.addMessages(translationData.translations);

                return quizApi.quiz.get();
            },
            function error(reason) {
                return $q.reject('could not fetch translation data');
            }
        ).then(
            function success(quizData) {
                if (!quizBuilder(quiz).load(quizData)) {
                    return $q.reject('could not build quiz');
                }

                sentinel.setQuizLoaded(true);
                return quizApi.state.current();
            },
            function error(reason) {
                return $q.reject('could not fetch quiz data');
            }
        ).then(
            function success(stateData) {
                quizStateSerializer(quiz).deserialize(stateData);

                return quiz.getStateId()
                    ? quizApi.result.get(quiz.getStateId())
                    : {}
                ;
            },
            function error(reason) {
                return {};
            }
        ).then(
            function (resultData) {
                quizResultDeserializer(quiz).deserialize(resultData);
            },
            function (reason) {
                return {};
            }
        ).then(
            function success() {
                var route  = redirect.getName('quizPage');
                var params = redirect.getParams({});
                redirect.clear();

                // Check where to send the user:
                //
                // 1. completely fresh state, goto the first page
                // 2. has a target page and the target is reachable, goto that page
                // 3. go to the last reachable page
                if (!quiz.getStateId()) {
                    params.pageId = quiz.pages.getFirst().id;
                } else if (!params.pageId || !quiz.pages.isReachable(params.pageId)) {
                    params.pageId = quiz.pages.getLastReachable().id;
                }

                $state.go(route, params);
            },
            function error(reason) {
                $scope.error = reason;
            }
        )
    }])

    .controller('QuizSynchronizeController', ['$scope', '$state', 'quiz', 'quizApi', 'quizStateSerializer', 'quizResultDeserializer', 'redirect', function($scope, $state, quiz, quizApi, quizStateSerializer, quizResultDeserializer, redirect) {
        var state = quizStateSerializer(quiz).serialize();

        quizApi.state.store(state)
            .then(
                function success(stateData) {
                    quizStateSerializer(quiz).deserialize(stateData);

                    return quiz.getStateId()
                        ? quizApi.result.get(quiz.getStateId())
                        : {}
                    ;
                },
                function error(reason) {
                    return {};
                }
            ).then(
                function success(resultData) {
                    quizResultDeserializer(quiz).deserialize(resultData);
                },
                function error(reason) {
                    return {};
                }
            )
            .finally(function() {
                var route  = redirect.getName('quizPage');
                var params = redirect.getParams({});
                redirect.clear();

                $state.go(route, params);
            })
        ;
    }])

    .controller('QuizPageController', ['$scope', '$anchorScroll', '$timeout', 'page', function($scope, $anchorScroll, $timeout, page) {
        $scope.page = page;

        // Jump to the top of the page, this must be done within
        // a timeout to ensure that the page is loaded. Which is
        // will be during next digest.
        $timeout(function() {
            $anchorScroll.yOffset = 100;
            $anchorScroll('page-top');
        });

    }])

    .controller('QuizPageDebugController', ['$scope', '$log', '$state', 'translation', 'quiz', 'quizStateSerializer', 'sentinel', function($scope, $log, $state, translation, quiz, quizStateSerializer, sentinel) {
        $scope.quiz = quiz;
        $scope.translation = translation;

        $scope.getState = function() {
            return quizStateSerializer(quiz).serialize();
        }

        $scope.clearState = function() {
            window.localStorage.clear(); //clear all local storage
            quizStateSerializer(quiz).deserialize({});
            $state.go('quizPage', { pageId: null });
        }

        $scope.restartQuiz = function() {
            window.localStorage.clear(); //clear all local storage

            quizStateSerializer(quiz).deserialize({});
            quiz.clear();
            sentinel.setQuizLoaded(false);
            $state.go('quizBootstrap');
        }

        $scope.dumpTranslation = function() {
            $log.info('wait');
        }
    }])
;

})(angular);