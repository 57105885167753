(function(angular) {

angular.module('app.common.quiz', ['app.common.component', 'app.common.translation', 'app.common.markdown', 'app.common.facebook'])

    .factory('quiz',  ['Quiz', function(Quiz) {
        return new Quiz();
    }])

    .factory('quizProgress', ['quiz', function(quiz) {

        function normalized() {
            var current = quiz.getCurrentPage();

            if (!current) {
                return 0;
            }

            var count = 0;
            var index = 0;

            angular.forEach(quiz.pages.getAll(), function(page) {
                if (page.isProgress) {
                    count = count + 1;
                }

                if (current.id == page.id) {
                    index = count;
                }
            })

            return count ? index / count : 0;
        }

        function questionNumber() {
            var current = quiz.getCurrentPage();
            var questionNumber = 0;

            questionNumber = parseInt(current.id.slice(-2));

            return questionNumber;
        }

        return {
            getNormalized: function() {
                return normalized();
            },
            getPercentage: function() {
                return Math.ceil(normalized() * 100);
            },
            getQuestionNumber: function() {
                return questionNumber();
            }
        }
    }])

    .factory('quizNavigator', ['$rootScope', 'quiz', function($rootScope, quiz) {
        return {
            first: function() {
                var page = quiz.pages.getFirst();

                if (!page) {
                    return false;
                }

                $rootScope.$emit('quizNavigateToPage', page.id);

                return true;
            },
            prev: function() {
                var page = quiz.getCurrentPage();

                if (!page || !page.prev) {
                    return false;
                }

                $rootScope.$emit('quizNavigateToPage', page.prev.id);

                return true;
            },
            next: function() {
                var page = quiz.getCurrentPage();

                if (!page || !page.next) {
                    return false;
                }

                //datalayer push
                dataLayer.push({'event':'pageview', 'virtualUrl': '/' + page.next.id});

                $rootScope.$emit('quizNavigateToPage', page.next.id);

                return true;
            }
        }
    }])

    .factory('quizTrigger', ['$rootScope', '$q', function($rootScope, $q) {
        return {
            requestResumeEmail: function() {
                var deferred = $q.defer();

                $rootScope.$emit('quizRequestResumeEmail', deferred);

                return deferred.promise;
            },
            requestReportEmail: function() {
                var deferred = $q.defer();

                $rootScope.$emit('quizRequestReportEmail', deferred);

                return deferred.promise;
            }
        }
    }])

    // Serializer for quiz state.
    //
    // To serialize the state:
    //   var state = quizStateSerializer(quiz).serialize();
    //
    // To deserialize a state:
    //   var success = quizStateSerializer(quiz).deserialize(state);
    //
    // Custom state serialization can be implemented using by adding
    // serializers to this provider.
    //
    .provider('quizStateSerializer', function() {
        var serializers = {};

        this.addSerializer = function(type, serializer) {
            serializers[type] = serializer;
        };

        this.$get = ['$log', '$injector', 'defaultQuizStateSerializer', function($log, $injector, defaultQuizStateSerializer) {
            return function(quiz) {

                var service = serializers.hasOwnProperty(quiz.getType()) ? serializers[quiz.getType()] : null;
                var serializer = service ? $injector.instantiate(service) : defaultQuizStateSerializer;

                return {
                    serialize: function() {
                        var state = serializer.serialize(quiz);
                        if (!state) {
                            return null
                        }

                        return angular.extend({
                            id:       quiz.getStateId(),
                            quizId:   quiz.getId()
                        }, state);
                    },
                    deserialize: function(state) {
                        if (!serializer.deserialize(quiz, state)) {
                            return false;
                        }

                        quiz.setStateId(state.id || null);

                        return true;
                    }
                }
            }
        }]
    })

    // Default serializer which adds the state of each
    // question under the questions key.
    .factory('defaultQuizStateSerializer', function() {
        return {
            serialize: function(quiz) {
                return {
                    answers: quiz.questions.getState()
                }
            },
            deserialize: function(quiz, state) {
                quiz.questions.setState(state.answers || []);

                return true;
            }
        }
    })

    // Results are loaded separately from state
    .provider('quizResultDeserializer', [function() {
        var deserializers = {};

        this.addDeserializer = function(type, deserializer) {
            deserializers[type] = deserializer;
        };

        this.$get = ['$injector', function($injector) {
            return function(quiz) {
                var service = deserializers.hasOwnProperty(quiz.getType()) ? deserializers[quiz.getType()] : null;
                var deserializer = service ? $injector.instantiate(service) : function(result) {};

                return {
                    deserialize: function(resultData) {
                        return deserializer(quiz, resultData);
                    }
                }
            }
        }]
    }])

    // Quiz builder.
    //
    // Register a quiz builder for a specific type.
    //
    .provider('quizBuilder', [function() {
        var builders = {};

        this.addBuilder = function(type, builder) {
            builders[type] = builder;
        };

        this.$get = ['$log', '$injector', function($log, $injector) {
            return function(quiz) {
                return {
                    load: function(data) {
                        var id     = data.id || null;
                        var type   = data.type || 'unknown';
                        var func   = builders.hasOwnProperty(type) ? builders[type] : null;

                        if (!func) {
                            $log.error('could not resolve builder for quiz of type: ' + type);
                            return false;
                        }

                        var builder = $injector.instantiate(func);

                        if (!builder(quiz, data)) {
                            return false;
                        }

                        quiz.setId(id);
                        quiz.setType(type);

                        return true;
                    }
                }
            }
        }]
    }])

    .factory('quizPaywall', ['QuizPaywall', function(QuizPaywall) {
        return new QuizPaywall;
    }])

;

})(angular);