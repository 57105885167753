(function(angular) {

angular.module('app.common.personality')

    .config(['quizQuestionTypeResolverProvider', function(resolver) {
        resolver.registerType('personalityQuestion', function(config) {
            var textId   = config.textId;
            var choices  = [];
            var selected = null;

            angular.forEach(config.choices || [], function(choice) {
                choices.push({
                    id:     choice.id     || 'unknown',
                    textId: choice.textId || 'unknown'
                })
            });

            this.getTextId = function() {
                return textId;
            }

            this.getChoices = function() {
                return choices;
            }

            this.getSelection = function(id) {
                return selected;
            }

            this.setSelection = function(id) {
                var result = false;

                angular.forEach(choices, function(choice) {
                    if (choice.id != id) {
                        return;
                    }

                    selected = choice.id;
                    result = true;
                });

                return result;
            }

            this.isCorrect = function() {
                return selected != null;
            }

            this.isAnswered = function() {
                return selected != null;
            }

            this.getState = function() {
                if (!this.isAnswered()) {
                    return null;
                }

                return {
                    selected: selected
                }
            }

            this.setState = function(state) {
                if (!state.selected) {
                    return true;
                }

                return this.setSelection(state.selected);
            }

            this.clearState = function() {
                selected = null;
            }
        });
    }])

    .config(['componentProvider', function(provider) {
        provider.register('personalityQuestion', ['quizNavigator', function(quizNavigator) {
            return {
                templateUrl: 'common/personality/component/personalityQuestion/personalityQuestion.html',
                link: function link(scope, element, attrs) {
                    scope.question = scope.component.question;

                    scope.select = function(choice) {
                        if (scope.question.setSelection(choice.id) === false) {
                            return;
                        }

                        quizNavigator.next();
                    }

                    scope.isSelected = function(choice) {
                        return scope.question.getSelection() == choice.id;
                    }
                }
            };
        }]);
    }])

;

})(angular);
