(function(angular) {

    angular.module('app.common.nordic')

        .config(['componentProvider', function(provider) {
            provider.register('nordicQuizBox', [function() {
                return {
                    templateUrl: 'common/nordic/component/nordicQuizBox/nordicQuizBox.html',
                    link: function link(scope, element, attrs) {
                        scope.components = scope.component.components || [];
                        scope.footerImage = scope.component.footerImage;
                        scope.footerImageText = scope.component.footerImageText;
                    }
                };
            }]);
        }])

    ;

})(angular);
