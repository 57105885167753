(function (angular) {

    angular.module('app.common.nordic')

        .config(['componentProvider', function (provider) {
            provider.register('nordicProfile', ['$rootScope', '$http', 'facebook', 'quizPaywall', 'sha256', function ($rootScope, $http, facebook, quizPaywall, sha256) {
                return {
                    templateUrl: 'common/nordic/component/nordicProfile/nordicProfile.html',
                    link: function link(scope) {
                        scope.profile.name_value = '';
                        scope.profile.last_name_value = '';
                        scope.profile.email_value = '';

                        scope.loginFacebook = function () {
                            facebook.user().then(
                                function success(user) {
                                    scope.profile.name_value = user.firstName;
                                    scope.profile.last_name_value = user.lastName;
                                    scope.profile.email_value = user.email;

                                }
                            );
                        }

                        /**
                        * Push leads from Quizes
                        *
                        */
                        scope.newsletterSignups = function newsletterSignups() {

                            var currentHost = window.location.hostname;
                            var pushToUrl = "";
                            var Zapier_sourcecode = "";

                            var data = new FormData();
                            data.append('emailaddress', scope.profile.email_value);
                            data.append('firstName', scope.profile.name_value);
                            data.append('lastName', scope.profile.last_name_value);
                            data.append('optin_phone', scope.profile.telefone.$viewValue);

                            /**
                             * Trivia ILL quizes
                             */
                            if (checkInHostname(['trivia.illvid.dk'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/881/1/EOGswW';
                                data.append('Zapier_sourcecode', 198825);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.illvet.se'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/882/1/agKP9Y';
                                data.append('Zapier_sourcecode', 298825);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.illvit.no'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/883/1/IO4CBy';
                                data.append('Zapier_sourcecode', 398825);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.tieku.fi'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/884/1/5Zqg8W';
                                data.append('Zapier_sourcecode', 498825);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }

                            /**
                             * Trivia HIS quizes
                             */
                            else if (checkInHostname(['trivia.historienet.dk'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/889/1/K3wakF';
                                data.append('Zapier_sourcecode', 198819);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.varldenshistoria.se'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/890/1/NOXhQh';
                                data.append('Zapier_sourcecode', 298819);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.historienet.no'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/891/1/v2SCro';
                                data.append('Zapier_sourcecode', 398819);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                            else if (checkInHostname(['trivia.historianet.fi'], currentHost)){
                                pushToUrl = 'https://www3.carmamail.com/mail/microsites/form/892/1/mGP38W';
                                data.append('Zapier_sourcecode', 498819);
                                scope.pushSignUp(pushToUrl, data);
                                return;
                            }
                        }
                        scope.pushSignUp = function (pushToUrl, data){
                            var xhr = new XMLHttpRequest();
                            xhr.open('POST', pushToUrl, true);
                            xhr.onload = function () {
                                // console.log(this.responseText);
                            };

                            xhr.send(data);
                        }

                        function checkInHostname(hostnamesArr, currentHost) {
                            for (var i = 0; i < hostnamesArr.length; i++) {
                                if (currentHost.indexOf(hostnamesArr[i]) > -1) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        scope.save = function () {
                            if (!scope.profile.$valid) {
                                angular.forEach(scope.profile.$error, function (field) {
                                    angular.forEach(field, function (errorField) {
                                        errorField.$setTouched();
                                    })
                                });
                                window.scrollTo(0, 0);
                                return false;
                            }

                            scope.newsletterSignups();

                            var profileData = {
                                firstName: scope.profile.name_value,
                                lastName: scope.profile.last_name_value,
                                email: scope.profile.email_value,
                                phoneNumber: scope.profile.telefone.$viewValue,
                                age: scope.profile.age.$viewValue,
                                education_level: scope.profile.education.$viewValue,
                                gender: scope.profile.gender.$viewValue,
                                subject: localStorage.getItem('nordicSubject')
                            };

                            var userId = sha256.convertToSHA256(profileData.email);
                            dataLayer.push({
                                "event": "newsletterSubscribeSubmit",
                                "newsletterSubscriptionFormat": "after questions",
                                'userId': userId,
                            });


                            $http.post('/api/state/nordic', profileData)
                                .then(function (resp) {
                                    dataLayer.push({
                                        "event": "newsletterSubscribeSuccess",
                                        "newsletterSubscriptionFormat": "after questions",
                                        'userId': userId,
                                    });

                                    window.localStorage.clear(); //clear all local storage
                                    window.location = '/result/' + resp.data.id;
                                }).catch(function (data) {
                                    dataLayer.push({
                                        "event": "newsletterSubscribeError",
                                        "newsletterSubscriptionFormat": "after questions",
                                        'userId': userId,
                                    });
                                });
                        };
                    }
                };
            }]);
        }])

    ;

})(angular);
