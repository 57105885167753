(function(angular) {

angular.module('app.common.quiz')

    .config(['componentProvider', function(provider) {
        provider.register('progressBar', ['quizProgress', function(quizProgress) {
            return {
                templateUrl: 'common/quiz/component/progressBar/progressBar.html',
                link: function link(scope, element, attrs) {
                    scope.percentage = quizProgress.getPercentage();
                }
            };
        }]);
    }])

;

})(angular);
