(function (angular) {
    angular.module('app.common.nordic')
        .config(['componentProvider', function (provider) {
            provider.register('nordicQuizBoxHero', [function () {
                return {
                    templateUrl: 'common/nordic/component/nordicQuizBoxHero/nordicQuizBoxHero.html',
                    link: function link(scope, element, attrs) {
                        var array= [];

                        var testCategories = {
                            nature: 'natur',
                            technique: 'teknik',
                            the_space: 'rummet',
                            human: 'mennesket',
                            civilizations: 'civilisationer',
                            wwii: 'verdenskrig',
                            discoveries: 'opdagelser',
                            faith_and_mystery: 'tromystik'
                        };

                        scope.iconClass = testCategories[localStorage.getItem('nordicSubject')] ? 'icon--' + testCategories[localStorage.getItem('nordicSubject')] : false;
                        scope.text = scope.component.text;
                        array = scope.component.text.split(": ");

                        if (array.length > 1) {
                            scope.textCat = 'general.'+array[0];
                            scope.textTitle = 'general.section_'+array[1]+'_title';
                        } else {
                            scope.textTitle = array[0];
                        }
                    }
                };
            }]);
        }])

    ;

})(angular);