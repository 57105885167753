(function(angular) {
    angular.module('app.common.quiz')
        .config(['componentProvider', function(provider) {
            provider.register('nordicProgressBar', ['quizProgress', function(quizProgress) {
                return {
                    templateUrl: 'common/nordic/component/nordicProgressBar/nordicProgressBar.html',
                    link: function link(scope, element, attrs) {
                        scope.percentage = quizProgress.getPercentage();
                        scope.activeSubject = scope.component.activeSubject;
                        scope.subjects = scope.component.subjects;
                    }
                };
            }]);
        }]);
})(angular);