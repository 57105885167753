(function(angular) {

    angular.module('app.common.personality')

        .config(['componentProvider', function(provider) {
            provider.register('personalityResultStatistics', [
                'personalityResults',
                'translator',
                function(results, translator) {
                    return {
                        templateUrl:
                            'common/personality/component/personalityResultStatistics/personalityResultStatistics.html',
                        link: function link(scope, element, attrs) {
                            scope.flows = scope.component.flows || 0;
                            scope.characters = results.getSameCharacterCount() || 0;
                            scope.traits = results.getSameTraitCount() || 0;
                            scope.match  = results.getBestMatch();
                            var params = {
                                name:   translator(scope.match.character.nameId),
                            };
                            scope.result_same_characters =
                                translator('general.result_same_characters', params);
                        }
                    };
            }]);
        }])

    ;

})(angular);
