(function(angular) {

angular.module('app.common.nordic')

    .config(['componentProvider', function(provider) {
        provider.register('nordicResultButtonPrint', [function() {
            return {
                templateUrl: 'common/nordic/component/nordicResult/nordicResultButtonPrint/nordicResultButtonPrint.html',
                link: function link(scope, element, attrs) {
                    
                }
            };
        }]);
    }])

;

})(angular);
