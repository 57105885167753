(function(angular) {

angular.module('app.common.nordic')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.share_facebook_button',
            'general.share_facebook_link',
            'general.share_facebook_image',
            'general.share_facebook_result_section_text'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('nordicResultButtonShareFacebook', ['nordicResults', 'facebook', 'translator', function(nordicResults, facebook, translator) {
            return {
                templateUrl:
                    'common/nordic/component/nordicResult/nordicResultButtonShareFacebook/nordicResultButtonShareFacebook.html',
                link: function link(scope, element, attrs) {
                    var score = nordicResults.getOverallScore();
                    var url = 'https://' + window.location.host;
                    var params = {
                        result:   score.result
                    };

                    scope.params = params;

                    scope.share = function() {
                        var data = {
                            link:   url,
                            picture: translator('general.share_facebook_image'),
                            name:    translator('general.share_facebook_result_total_text', params)
                        };

                        facebook.share(data).then(
                            function success(result) {
                                //console.log(result);
                            },
                            function error(reason) {
                                //console.error(reason);
                            }
                        )
                    }
                }
            };
        }]);
    }])

;

})(angular);
