(function(angular) {

angular.module('app.common.iq')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.section_result_correct',
            'general.section_result_incorrect',
            'general.section_result_unanswered'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('iqResultAnswerDistributionBar', ['iqResults', function(iqResults) {
            return {
                templateUrl: 'common/iq/component/iqResult/iqResultAnswerDistributionBar/iqResultAnswerDistributionBar.html',
                link: function link(scope, element, attrs) {
                    var questions = scope.component && scope.component.section
                        ? iqResults.getSectionQuestions(scope.component.section)
                        : iqResults.getQuestions()
                    ;

                    scope.size              = scope.component && scope.component.size ? scope.component.size : '';
                    scope.totalCount        = questions.total;
                    scope.correctCount      = questions.correct;
                    scope.correctPercent    = questions.percentCorrect;
                    scope.incorrectCount    = questions.incorrect;
                    scope.incorrectPercent  = questions.percentIncorrect;
                    scope.unansweredCount   = questions.unanswered;
                    scope.unansweredPercent = questions.percentUnanswered;
                }
            };
        }]);
    }])

;

})(angular);
