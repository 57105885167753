(function(angular) {

angular.module('app.common.nordic')

    .config(['componentProvider', function(provider) {
        provider.register('nordicResultTotal', ['quizTrigger', function(quizTrigger) {
            return {
                templateUrl: 'common/nordic/component/nordicResult/nordicResultTotal/nordicResultTotal.html',
                link: function link(scope, element, attrs) {
                    scope.header = scope.component.header;
                    scope.result = scope.component.score() ? scope.component.score().result : 0;
                    quizTrigger.requestReportEmail();
                }
            };
        }]);
    }])

;

})(angular);
