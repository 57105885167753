(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityResultButtonReport', [
            'personalityResults',
            'translator',
            'quizTrigger',
            function(results, translator, quizTrigger) {
                return {
                    templateUrl:
                        'common/personality/component/personalityResultButtonReport/personalityResultButtonReport.html',
                    link: function link(scope, element, attrs) {
                        scope.match  = results.getBestMatch();
                        scope.stateId = scope.component.quiz.getStateId();
                        scope.locale = scope.component.locale || 'da';

                        var params = {
                            name:   translator(scope.match.character.nameId),
                        };
                        scope.diploma_teaser = translator('general.diploma_teaser', params);
                        quizTrigger.requestReportEmail();
                    }
                };
        }]);
    }])
;

})(angular);
