(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('tabs', [function() {
            return {
                templateUrl: 'common/component/tabs/tabs.html',
                link: function link(scope, element, attrs) {
                    scope.tabs = scope.component.tabs || [];
                    scope.open = 0;

                    scope.show = function(index) {
                        scope.open = index;
                    }
                }
            };
        }]);
    }])

;

})(angular);
