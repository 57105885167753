(function (angular) {
    angular.module('app', ['ui.router', 'app.section.quiz', 'app.configuration', 'app.common.environment'])

        .config(['$urlRouterProvider', '$locationProvider', function ($urlRouterProvider, $locationProvider) {
            $urlRouterProvider.otherwise('/');
            $locationProvider.html5Mode(false);
        }])

        .config(['environmentProvider', 'configuration', function (provider, configuration) {
            angular.forEach(configuration, function (value, key) {
                provider.set(key, value);
            })
        }])
        .run(['$rootScope', '$location', '$window', 'quizStateSerializer', 'quiz', 'quizApi', '$http', 'sha256', function ($rootScope, $location, $window, quizStateSerializer, quiz, quizApi, $http, sha256) {
            $rootScope.$on('$locationChangeStart', function () {
                var dataLayer = window.dataLayer = window.dataLayer || [];

                if (dataLayer[1]) {
                    dataLayer[1].contentType = 'quiz';
                }

                var state = quizStateSerializer(quiz).serialize();
                var userId = null;

                if (typeof state.user !== 'undefined') {
                    userId = sha256.convertToSHA256(state.user.email);
                }

                quizApi.translation.get().then(function (resp) {
                    $window.locale = resp.locale;
                });


                function checkVariable() {
                    if (state.quizId) {
                         var pageBrand = 'ILL';

                        if (state.quizId === 'personality_historienet') {
                            pageBrand = 'HIS';
                        }

                        var pageMarket = typeof $window.locale !== 'undefined' ? $window.locale.toUpperCase() : 'DA';

                        if (pageMarket === 'SV') {
                            pageMarket = 'SE';
                        }

                        if (pageMarket === 'DA') {
                            pageMarket = 'DK';
                        }

                        var keys = Object.keys(state.answers);
                        var questionNo = keys.length;
                        var key = (questionNo < 10) ? "0" + questionNo : questionNo;

                        if (questionNo === 0) {
                            for (var i = 0; i < dataLayer.length; i++) {
                                if (typeof dataLayer[i].pageId !== "undefined" && dataLayer[i].pageId === $location.$$path) {
                                    return;
                                }
                            }

                            if($location.$$path != '/bootstrap'){
                                dataLayer.push({
                                    "pageBrand": pageBrand,
                                    "siteType": 'quiz',
                                    "pageMarket": pageMarket,
                                    "pageId": $location.$$path,
                                    "contentType": 'quiz',
                                    "pageName": $window.document.title,
                                    "pageStatus": true
                                });
                            }
                        }
                        else if (questionNo !== 0 && window.pushedQuestion !== questionNo) {
                            // This is triggered when the answers a question
                            dataLayer.push({
                                "event": "questionAnswer",
                                "questionNo": questionNo,
                                "questionName": key
                            });
                            window.pushedQuestion = questionNo;
                        }
                    }
                }

                setTimeout(checkVariable, 200);


            });
        }])

    ;

})(angular);
