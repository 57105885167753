(function(angular) {

angular.module('app.common.quiz')

    .config(['translationProvider', function(provider) {
        provider.declareMessageKeys([
            'general.advance_button'
        ]);
    }])

    .config(['componentProvider', function(provider) {
        provider.register('advanceButton', ['quizNavigator', function(quizNavigator) {
            return {
                templateUrl: 'common/quiz/component/advanceButton/advanceButton.html',
                link: function link(scope, element, attrs) {
                    scope.advance = function() {
                        quizNavigator.next();
                    }
                }
            };
        }]);
    }])

;

})(angular);
