(function(angular) {

angular.module('app.common.personality')

    .config(['componentProvider', function(provider) {
        provider.register('personalityStatisticsParticipation', [function() {
            return {
                templateUrl: 'common/personality/component/personalityStatisticsParticipation/personalityStatisticsParticipation.html',
                link: function link(scope, element, attrs) {
                    scope.flows = scope.component.flows || 0;
                    scope.answers = scope.component.answers || 0;
                }
            };
        }]);
    }])

;

})(angular);
