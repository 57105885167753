(function (angular) {

    var testCategories = {
        nature: 'natur',
        technique: 'teknik',
        the_space: 'rummet',
        human: 'mennesket',
        civilizations: 'civilisationer',
        wwii: 'verdenskrig',
        discoveries: 'opdagelser',
        faith_and_mystery: 'tromystik'
    };
    angular.module('app.common.nordic', ['app.common.translation', 'app.common.component', 'app.common.quiz', 'app.common.environment', 'app.common.facebook'])

        .config(['quizBuilderProvider', function (provider) {
            provider.addBuilder('nordic_test', ['nordicTestBuilder', function (builder) {
                return builder;
            }])
        }])

        .config(['quizStateSerializerProvider', function (provider) {
            provider.addSerializer('nordic_test', ['nordicStateSerializer', function (serializer) {
                return serializer;
            }])
        }])

        .config(['quizResultDeserializerProvider', function (provider) {
            provider.addDeserializer('nordic_test', ['nordicResultDeserializer', function (deserializer) {
                return deserializer;
            }])
        }])

        .factory('nordicStateSerializer', ['quizPaywall', function (quizPaywall) {

            return {
                serialize: function (quiz) {
                    return {
                        user: quizPaywall.getState(),
                        answers: quiz.questions.getState()
                    }
                },
                deserialize: function (quiz, state) {
                    quizPaywall.setState(state.user || {});
                    quiz.questions.setState(state.answers || []);

                    return true;
                }
            }
        }])

        .factory('nordicResultDeserializer', ['nordicResults', function (results) {
            return function (quiz, resultData) {
                results.setState(resultData || {});

                return true;
            }
        }])

        .factory('nordicResults', ['NordicResults', function (NordicResults) {
            return new NordicResults;
        }])

        .factory('nordicTestBuilder', ['$log', 'translation', 'quizStateSerializer', 'QuizPage', 'QuizQuestion', 'quizPaywall', 'nordicResults', 'nordicTestBuilderHelpers', '$rootScope', '$http', function ($log, translation, quizStateSerializer, QuizPage, QuizQuestion, quizPaywall, nordicResults, helpers, $rootScope, $http) {
            var obj = this;
            obj.data = null;

            function prepareQuizData(data) {
                return angular.merge({
                    siteNameTextId: 'unknown',
                    siteUrlTextId: 'unknown',
                    description: '',
                    information: [],
                    sections: []
                }, data);
            }

            function prepareSectionData(data) {
                return angular.extend({
                    id: 'unknown',
                    titleTextId: 'unknown',
                    instructionsTextId: 'unknown',
                    instructionsBreakTextId: 'unknown',
                    questions: []
                }, data);
            }

            function prepareSectionQuestions(section) {
                var questions = {
                    test: null,
                    real: []
                };

                angular.forEach(section.questions, function (data) {
                    var question = new QuizQuestion(data);

                    if (!question) {
                        $log.error('could not create question, or the name was not unique')
                        return;
                    }

                    if (data.test) {
                        questions.test = question;
                        return;
                    }

                    questions.real.push(question);
                });

                return questions;
            }

             return function (quiz, data) {
                $rootScope.$on('quizLoad', function (event, subjectId) {
                    $http.get('/api/quiz?subject=' + subjectId).then(function (resp) {
                        obj.data = prepareQuizData(resp.data);
                    });
                });

                // Save profile data
                $rootScope.$on('saveProfileData', '$state', 'quizApi', function (event, profileData, $state, quizApi) {
                    quizApi.state.store(state);
                });

                // Make sure that the quiz contains the fields we need
                obj.data = prepareQuizData(data);

                var logo = 'logo_' + globalLocale + '_sort';

                if (globalNordicContextType === 'his') {
                    logo = 'Logo_his_' + globalLocale;
                }

                var components = [
                    helpers.makeNordicQuizBoxHeadingLogoAndSubText('general.intro_header_text', logo +'.png'),
                    helpers.makeNordicQuizBoxHeading('general.intro_header'),
                    helpers.makeNordicQuizTextBox('general.intro_text_box', 'center'),
                ]
                if (globalLocale === 'da') {
                    components.push(helpers.makeNordicQuizTextBox('general.intro_text_box_post', ['center', 'italic']))
                }
                components.push(helpers.makeNordicQuizBoxButtonWrap('general.intro_button', '/#/subject'))

                        // Add an intro page
                        quiz.pages.add(new QuizPage({
                            id: 'intro',
                            components: [helpers.makeWrapper('intro', [
                                {
                                    type: 'nordicQuizBox',
                                    components: components,
                                    footerImage: 'footer-forside.svg',
                                    footerImageText: globalLocale === 'da' ? '' : 'general.intro_footer_image_text'
                                }
                            ])
                            ]
                        }));

                // Add an select subject page
                quiz.pages.add(new QuizPage({
                    id: 'subject',
                    components: [helpers.makeWrapper('subject', [
                        {
                            type: 'nordicQuizBox',
                            components: [
                                helpers.makeNordicQuizBoxHero('general.subject_page_header'),
                                helpers.makeNordicCategoryList([
                                    [
                                        {
                                            subject_id: 'nature',
                                            title: 'general.nature',
                                            description: 'general.nature_subject_description',
                                            subject: 'natur'
                                        },
                                        {
                                            subject_id: 'technique',
                                            title: 'general.technique',
                                            description: 'general.technique_subject_description',
                                            subject: 'teknik'
                                        }
                                    ],
                                    [
                                        {
                                            subject_id: 'the_space',
                                            title: 'general.the_space',
                                            description: 'general.the_space_subject_description',
                                            subject: 'rummet'
                                        },
                                        {
                                            subject_id: 'human',
                                            title: 'general.human',
                                            description: 'general.human_subject_description',
                                            subject: 'mennesket'
                                        }
                                    ],
                                    [
                                        {
                                            subject_id: 'civilizations',
                                            title: 'general.civilizations',
                                            description: 'general.civilizations_subject_description',
                                            subject: 'civilisationer'
                                        },
                                        {
                                            subject_id: 'wwii',
                                            title: 'general.wwii',
                                            description: 'general.wwii_subject_description',
                                            subject: 'verdenskrig'
                                        }
                                    ],
                                    [
                                        {
                                            subject_id: 'discoveries',
                                            title: 'general.discoveries',
                                            description: 'general.discoveries_subject_description',
                                            subject: 'opdagelser'
                                        },
                                        {
                                            subject_id: 'faith_and_mystery',
                                            title: 'general.faith_and_mystery',
                                            description: 'general.faith_and_mystery_subject_description',
                                            subject: 'tromystik'
                                        }
                                    ]
                                ])
                            ]
                        }
                    ])]
                }));


                // Generate all tests by category
                angular.forEach(testCategories, function (nameDK, name) {
                    quiz.pages.add(new QuizPage({
                        id: name,
                        components: [helpers.makeWrapper(name, [
                            {
                                type: 'nordicQuizBox',
                                components: [
                                    helpers.makeNordicQuizBoxHero('general.' + name, nameDK),
                                    helpers.makeNordicQuizTextBox('general.' + name + '_start_page', ''),
                                    helpers.makeNordicAdvanceButton('general.intro_button', '', name),
                                ],
                                footerImage: 'footer-' + nameDK + '.svg'
                            }
                        ])
                        ]
                    }));

                });

                // Prepare section progression
                var makeSections = helpers.makePrepareQuestionSections(data.sections);
                var questionCounter = 1;

                // Create pages for each section:
                // * Instruction page with optional test question
                // * A page for each question
                // * A results page showing the responses for questions in the section
                angular.forEach(obj.data.sections, function (section, sectionIndex) {
                    var section = prepareSectionData(section);
                    var questions = prepareSectionQuestions(section);
                    var lastSection = sectionIndex == (data.sections.length - 1);

                    if (questions.test && quiz.questions.add(questions.test)) {
                        quiz.pages.add(new QuizPage({
                            id: section.id,
                            isProgress: true,
                            components: [helpers.makeWrapper('section-' + section.id, [
                                makeSections(section.id),
                                helpers.makeNordicProgressBar(),
                                helpers.makeHeader(section.titleTextId),
                                helpers.makeMarkdown(section.instructionsTextId),
                                helpers.makeQuestion(questions.test, questionCounter),
                                helpers.makeAdvanceIfCorrectButton(questions.test)
                            ])],
                            isCompleted: function () {
                                return questions.test.isAnswered() && questions.test.isCorrect();
                            }
                        }));
                    }

                    var addedMemoryBackwardsBreak = false;

                    // Create a page for each question
                    angular.forEach(questions.real, function (question, questionIndex) {
                        var lastQuestion = questionIndex === 9;

                        quiz.pages.add(new QuizPage({
                            id: question.id,
                            isProgress: true,
                            isSync: lastQuestion, // sync on last question
                            components: [
                                {
                                    type: 'nordicQuizBox',
                                    components: [
                                        helpers.makeNordicQuizBoxHero(data.id + ': ' + section.id, testCategories[data.id]),
                                        helpers.makeQuestion(quiz.questions.add(question), questionCounter, data),
                                    ],
                                    footerImage: 'footer-' + testCategories[data.id] + '.svg'
                                }
                            ],

                            isCompleted: function () {
                                return question.isAnswered();
                            }
                        }));
                        questionCounter++;
                    });
                });

                 quiz.pages.add(new QuizPage({
                    id: 'profile',
                    components: [helpers.makeWrapper('profile', [
                        {
                            type: 'nordicQuizBox',
                            components: [
                                helpers.makeNordicQuizBoxHero('general.before_continue_title', 'mennesket'),
                                helpers.makeNordicQuizTextBox('general.before_continue_text'),
                                helpers.makeNordicProfile(),
                            ],
                            footerImage: 'footer-forside.svg'
                        }
                    ])
                    ]
                }));

                return true;
            }

        }])
        .factory('nordicTestBuilderHelpers', [function () {

            var helpers = {};

            helpers.makeHeader = function (id, data) {
                return {
                    type: 'header',
                    id: id,
                    data: data || {}
                };
            };

            helpers.makeParagraph = function (id, data) {
                return {
                    type: 'paragraph',
                    id: id,
                    data: data || {}
                }
            };

            helpers.makeMarkdown = function (id, data) {
                return {
                    type: 'markdown',
                    id: id,
                    data: data || {}
                }
            };

            helpers.makeList = function (items) {
                return {
                    type: 'list',
                    items: items,
                };
            };

            helpers.makeAdvanceButton = function () {
                return {
                    type: 'advanceButton',
                };
            };

            helpers.makeAdvanceIfAnsweredButton = function (question) {
                return {
                    type: 'advanceIfAnsweredButton',
                    question: question
                };
            };

            helpers.makeAdvanceIfCorrectButton = function (question) {
                return {
                    type: 'advanceIfCorrectButton',
                    question: question
                };
            };

            helpers.makeWrapper = function (areas, components) {

                if (angular.isString(areas)) {
                    areas = [areas];
                }

                return {
                    type: 'nordicWrapper',
                    areas: areas,
                    components: components
                };
            };

            helpers.makeTabs = function (tabs) {
                return {
                    type: 'tabs',
                    tabs: tabs
                };
            };

            helpers.makeTab = function (headerTextId, components) {
                return {
                    headerTextId: headerTextId,
                    components: components
                };
            };

            helpers.makeColumns = function (columns) {
                return {
                    type: 'columns',
                    columns: columns
                };
            };

            helpers.makeColumn = function (width, components) {
                return {
                    width: width,
                    components: components,
                }
            };

            helpers.makeNordicProgressBar = function (activeSubject, subjects) {
                return {
                    type: 'nordicProgressBar',
                    activeSubject: activeSubject,
                    subjects: subjects
                };
            };

            helpers.makeInstructionTabs = function (instructions) {
                var tabs = [];

                angular.forEach(instructions, function (instruction) {
                    tabs.push(helpers.makeTab(instruction.headerTextId, [
                        helpers.makeMarkdown(instruction.bodyTextId)
                    ]));
                });

                return helpers.makeTabs(tabs);
            };

            helpers.makePrepareSections = function (sections) {
                return function (active) {
                    return {
                        type: 'sections',
                        sections: sections,
                        active: active
                    }
                }
            };

            helpers.makePrepareQuestionSections = function (sections) {
                var result = [];

                angular.forEach(sections, function (section) {
                    result.push({
                        id: section.id,
                        textId: section.titleTextId
                    });
                });

                return helpers.makePrepareSections(result);
            };

            helpers.makeQuestion = function (question, questionsPassed, data) {
                var subjectA = '';
                var subjectB = '';
                var subjectC = '';
                var subjectD = '';

                if (data.sections.length == 4 && data.sections !== undefined) {
                    subjectA = data.sections[0].titleTextId;
                    subjectB = data.sections[1].titleTextId;
                    subjectC = data.sections[2].titleTextId;
                    subjectD = data.sections[3].titleTextId;

                }


                return {
                    type: 'nordicQuestionTimedTextChoice',
                    question: question,
                    questionsPassed: questionsPassed,
                    subjects: {'a': subjectA, 'b': subjectB, 'c': subjectC, 'd': subjectD},
                    activeSubject: 'a'
                };
            };

            helpers.changeChoicesKeys = function (question) {
                return question;
            };

            helpers.makeResultAnswerDistributionBar = function (section) {
                return {
                    type: 'nordicResultAnswerDistributionBar',
                    section: section
                };
            };

            helpers.makePaywall = function () {
                return {
                    type: 'paywall'
                };
            };

            helpers.makeProgressSaveButton = function () {
                return {
                    type: 'progressSaveButton'
                };
            };

            helpers.makeNordicQuizBoxHeading = function (text) {
                return {
                    type: 'nordicQuizBoxHeading',
                    text: text
                }
            };

            helpers.makeNordicQuizBoxHeadingLogoAndSubText = function (text, logo) {
                return {
                    type: 'nordicQuizBoxHeadingLogoAndSubText',
                    text: text,
                    logo: logo,
                }
            };

            helpers.makeNordicQuizTextBox = function (text, offset) {
                if (!(offset instanceof Array)) {
                    offset = [offset]
                }

                return {
                    type: 'nordicQuizTextBox',
                    text: text,
                    offset: offset.map(function (o) { return ('quiz-box__text-box--' + o) }).join(' ')
                }
            };

            helpers.makeNordicQuizBoxButtonWrap = function (buttonText, link) {
                return {
                    type: 'nordicQuizBoxButtonWrap',
                    buttonText: buttonText,
                    link: link
                }
            };

            helpers.makeNordicAdvanceButton = function (buttonText, link, subject) {
                return {
                    type: 'nordicAdvanceButton',
                    buttonText: buttonText,
                    link: link,
                    subject: subject
                }
            };

            helpers.makeNordicQuizBoxHero = function (text, subject) {
                return {
                    type: 'nordicQuizBoxHero',
                    subject: subject ? subject : false,
                    text: text
                };
            };

            helpers.makeNordicCategoryItem = function (subject, url, title, description, subject_id) {
                return {
                    type: 'nordicCategoryItem',
                    subject: subject,
                    url: url,
                    title: title,
                    description: description,
                    subject_id: subject_id
                }
            };

            helpers.makeNordicCategoryItemsRow = function (row) {
                var categoryItems = [];

                for (var i = 0; i < row.length; i++) {
                    categoryItems.push(helpers.makeNordicCategoryItem(row[i].subject, row[i].url, row[i].title, row[i].description, row[i].subject_id));
                }

                return {
                    type: 'nordicCategoryItemsRow',
                    components: categoryItems
                };
            };

            helpers.makeNordicCategoryList = function (data) {
                var rows = [];

                for (var i = 0; i < data.length; i++) {
                    rows.push(helpers.makeNordicCategoryItemsRow(data[i]));
                }

                return {
                    type: 'nordicCategoryList',
                    components: rows
                }

            };

            helpers.makeNordicQuizBoxImage = function (imgName, altImage) {
                return {
                    type: 'nordicQuizBoxImage',
                    imgName: imgName,
                    altImage: altImage
                };

            };

            helpers.makeNordicQuestionCounter = function (seconds) {
                return {
                    type: "nordicQuestionCounter",
                    seconds: seconds
                }
            };

            helpers.makeNordicProfile = function () {
                return {
                    type: "nordicProfile",
                }
            };

            helpers.makeNordicRegistrationForm = function() {
                return {
                    type: "nordicRegistrationForm"
                }
            };

            return helpers;
        }])
        .factory('facebookService', function($q) {
            return {
                getMyLastName: function() {
                    var deferred = $q.defer();
                    FB.api('/me', {
                        fields: 'last_name'
                    }, function(response) {
                        if (!response || response.error) {
                            deferred.reject('Error occured');
                        } else {
                            deferred.resolve(response);
                        }
                    });
                    return deferred.promise;
                }
            }
        });

    ;

})(angular);
