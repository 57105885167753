(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('markdown', [function() {
            return {
                templateUrl: 'common/component/markdown/markdown.html',
                link: function link(scope, element, attrs) {
                    scope.id   = scope.component.id;
                    scope.data = scope.component.data;
                }
            };
        }]);
    }])
;

})(angular);
