(function(angular) {

angular.module('app.common.component')

    .config(['componentProvider', function(provider) {
        provider.register('pull', [function() {
            return {
                templateUrl: 'common/component/pull/pull.html',
                link: function link(scope, element, attrs) {
                    scope.class = 'pull-left';

                    switch (scope.component.direction || 'left') {
                    case 'right':
                        scope.class = 'pull-right';
                        break;
                    case 'center':
                        scope.class = 'pull-center';
                        break;
                    default:
                        scope.class = 'pull-left';
                        break;
                    }

                    scope.class = scope.component.direction && scope.component.direction == 'right' ? 'pull-right' : 'pull-left';
                    scope.components = scope.component.components || [];
                }
            };
        }]);
    }])
;

})(angular);
