(function(angular) {

angular.module('app.common.component')

    .config(['componentDirectiveResolverProvider', function(provider) {
        provider.registerFallback('component-placeholder');
    }])

    .config(['componentProvider', function(provider) {
        provider.register('placeholder', [function() {
            return {
                templateUrl: 'common/component/placeholder/placeholder.html',
                link: function link(scope, element, attrs) {
                    scope.type = scope.component.type;
                    scope.content = scope.component.content;
                }
            };
        }]);
    }])

;

})(angular);
