(function(angular) {

angular.module('app.common.iq')

    .config(['quizQuestionTypeResolverProvider', function(resolver) {
        resolver.registerType('visualMatrix', function(config) {
            this.image = config.image;
            this.choices = config.choices;

            var selected = null;

            this.setSelection = function(id) {
                var result = false;

                angular.forEach(this.choices, function(choice) {
                    if (choice.id == id) {
                        selected = id;
                        result = true;
                    }
                }, this);

                return result;
            }

            this.getSelection = function() {
                return selected;
            }

            this.isAnswered = function() {
                return selected != null;
            }

            this.isCorrect = function() {
                var ok = false;
                var id = this.getSelection();

                angular.forEach(this.choices, function(choice) {
                    if (choice.id != id) {
                        return
                    }

                    if (choice.correct) {
                        ok = true;
                    }
                });

                return ok;
            }

            this.getState = function() {
                if (!this.isAnswered()) {
                    return null;
                }

                return {
                    selected: selected
                }
            }

            this.setState = function(state) {
                if (!state.selected) {
                    return true;
                }

                return this.setSelection(state.selected);
            }

            this.clearState = function() {
                selected = null;
            }
        });
    }])

    .config(['componentProvider', function(provider) {
        provider.register('iqQuestionVisualMatrix', [function() {
            return {
                templateUrl: 'common/iq/component/iqQuestionVisualMatrix/iqQuestionVisualMatrix.html',
                link: function link(scope, element, attrs) {
                    scope.question = scope.component.question;

                    scope.select = function(choice) {
                        scope.question.setSelection(choice.id);
                    }

                    scope.isSelected = function(choice) {
                        return scope.question.getSelection() == choice.id;
                    }
                }
            };
        }]);
    }])

;

})(angular);
