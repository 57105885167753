(function(angular) {

angular.module('app.common.iq')

    .config(['quizQuestionTypeResolverProvider', function(resolver) {
        resolver.registerType('memorySequence', function(config) {
            this.sequence = config.sequence || '1234';
            this.reversed = config.reversed || false;

            var answer = '';

            this.setAwswer = function(value) {
                answer = value;
                return true;
            }

            this.getAnswer = function() {
                return answer;
            }

            this.isAnswered = function() {
                return answer != '';
            }

            this.isCorrect = function() {
                return this.sequence == answer;
            }

            this.getState = function() {
                if (!this.isAnswered()) {
                    return null;
                }

                return {
                    answer: answer
                };
            }

            this.setState = function(state) {
                return this.setAwswer(state.answer || '');
            }

            this.clearState = function() {
                answer = '';
            }
        });
    }])

    .config(['componentProvider', function(provider) {
        provider.register('iqQuestionMemorySequence', ['memorySequenceLooper', 'memorySequenceWaiter', 'environment', function(looper, waiter, environment) {
            return {
                templateUrl: 'common/iq/component/iqQuestionMemorySequence/iqQuestionMemorySequence.html',
                link: function link(scope, element, attrs) {
                    var question = scope.component.question;

                    scope.answer = question.getAnswer();
                    scope.running = false;
                    scope.answerEnabled = false;
                    scope.currentCharacter = '';
                    scope.debug = environment('debug', false);

                    scope.$watch('answer', function() {
                        question.setAwswer(scope.answer);
                    })

                    scope.start = function() {
                        if (scope.running) {
                            return;
                        }

                        scope.running = true;

                        waiter(0)
                            .then(function() {
                                scope.answer = '';
                                scope.answerEnabled = false;
                                scope.currentCharacter = '';

                                return looper(750, question.sequence.split(''));
                            })
                            .then(
                                function(d) { return waiter(750) },
                                function(e) {},
                                function(c) {
                                    scope.currentCharacter = c;
                                }
                            )
                            .finally(function() {
                                scope.currentCharacter = '';
                                scope.answerEnabled = true;
                                scope.running = false;
                            })
                        ;
                    }

                    // Debug button for answering questions
                    scope.debugAnswerCorrectly = function() {
                        if (!scope.debug) {
                            return;
                        }

                        scope.answer = question.reversed
                            ? question.sequence.split('').reverse().join('')
                            : question.sequence
                        ;
                    }

                    scope.debugAnswerWrongly = function() {
                        scope.answer = 'wrong';
                    }
                }
            };
        }]);
    }])

    // Enforce that input contains no non-alphanumeric characters
    // Remember to use with ng-trim="false" to ensure parsing
    // happens even when input contains trailing spaces
    .directive('memorySequenceInput', [function() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                if(!ngModelCtrl) {
                    return;
                }

                ngModelCtrl.$parsers.push(function(value) {
                    if (angular.isUndefined(value)) {
                        var value = '';
                    }

                    var cleaned = value.replace(/\W/g, '');

                    if (value !== cleaned) {
                        ngModelCtrl.$setViewValue(cleaned);
                        ngModelCtrl.$render();
                    }

                    return cleaned;
                });
            }
        };
    }])

    // Returns a promise that will notify with each element after a set interval.
    // The promise is resolved once all elements have been notified
    .factory('memorySequenceLooper', ['$q', '$interval', function($q, $interval) {
        return function(interval, elements) {
            var deferred = $q.defer();
            var index = 0;

            function tick() {
                if (index < elements.length) {
                    deferred.notify(elements[index++]);
                }
            }

            $interval(tick, interval, elements.length).then(function() {
                deferred.resolve();
            });

            return deferred.promise;
        }
    }])

    // Returns a promise which is resolved when a timeout has passed
    .factory('memorySequenceWaiter', ['$q', '$timeout', function($q, $timeout) {
        return function(duration) {
            var deferred = $q.defer();

            $timeout(function() {
                deferred.resolve();
            }, duration);

            return deferred.promise;
        }
    }])

;

})(angular);
