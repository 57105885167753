(function(angular) {

angular.module('app.common.iq')

    .config(['quizQuestionTypeResolverProvider', function(resolver) {
        resolver.registerType('timedTextChoice', function(config) {
            this.seconds = config.seconds;
            this.choices = config.choices;
            this.textId  = config.textId;

            var selected = null;
            var timedOut = false;

            this.setSelection = function(id) {
                angular.forEach(this.choices, function(choice) {
                    if (choice.id == id) {
                        selected = id;
                    }
                });
            }

            this.setTimedOut = function(value) {
                timedOut = value;
            }

            this.getTimedOut = function() {
                return timedOut;
            }

            this.getSelection = function() {
                return selected;
            }

            this.isAnswered = function() {
                return timedOut || selected != null;
            }

            this.isCorrect = function() {
                var ok = false;
                var id = this.getSelection();

                angular.forEach(this.choices, function(choice) {
                    if (choice.id != id) {
                        return
                    }

                    if (choice.correct) {
                        ok = true;
                    }
                });

                return ok;
            }

            this.getState = function() {
                if (!this.isAnswered()) {
                    return null;
                }

                return {
                    selected: selected,
                    timedOut: timedOut
                };
            }

            this.setState = function(state) {
                this.setTimedOut(state.timedOut || false);
                this.setSelection(state.selected || null);

                return true;
            }

            this.clearState = function() {
                selected = null;
                timedOut = false;
            }
        });
    }])

    .config(['componentProvider', function(provider) {
        provider.register('iqQuestionTimedTextChoice', ['$interval', function($interval) {
            return {
                templateUrl: 'common/iq/component/iqQuestionTimedTextChoice/iqQuestionTimedTextChoice.html',
                link: function link(scope, element, attrs) {
                    scope.question = scope.component.question;
                    scope.seconds = scope.question.seconds;

                    scope.select = function(choice) {
                        if (scope.question.getTimedOut()) {
                            return;
                        }

                        scope.question.setSelection(choice.id);
                    }

                    scope.isSelected = function(choice) {
                        return scope.question.getSelection() == choice.id;
                    }

                    scope.isTimedOut = function() {
                        return scope.question.getTimedOut();
                    }

                    var ticker = $interval(null, 1000, scope.seconds);

                    ticker.then(function() {
                        scope.question.setTimedOut(true);
                    }, function() {
                        // nothing here
                    }, function() {
                        scope.seconds = scope.seconds > 0 ? scope.seconds - 1 : 0;
                    });

                    scope.$on('$destroy', function() {
                        $interval.cancel(ticker);
                    });
                }
            };
        }]);
    }])
;

})(angular);
